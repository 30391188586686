import {
  izaAccessPermission,
  sosAccessPermission,
  connectAccessPermission,
  outboundCallInternalAccessPermission,
  jusdoitAccessPermission,
  accessCallLogPermission,
  accessManagementClientPermission,
  accessManagementUserPermission,
  accessBillPagePermission,
  outboundCallExternalAccessPermission,
} from '../utils/constants';

export default [
  {
    path: '/',
    component: 'modules/menu/pages/MainMenu',
    auth: true,
  },
  {
    path: '/call-logs',
    component: 'modules/callLog/pages/CallLog',
    pagePermission: [accessCallLogPermission, izaAccessPermission],
    auth: true,
  },
  {
    path: '/sign-in',
    component: 'modules/auth/pages/SignIn',
    layout: 'Minimal',
  },

  {
    path: '/iza-call',
    component: 'modules/izacall/pages/IzaCall',
    pagePermission: [izaAccessPermission],
    auth: true,
    redirect: '/iza-call/inquiry-list',
  },
  {
    path: '/iza-call/inquiry-list',
    component: 'modules/izacall/pages/IzaCall',
    pagePermission: [izaAccessPermission],
    auth: true,
  },
  {
    path: '/iza-call/inquiry-form',
    component: 'modules/izacall/pages/IzaCall',
    pagePermission: [izaAccessPermission],
    auth: true,
  },
  {
    path: '/iza-call/inquiry/:id',
    component: 'modules/izacall/pages/IzaCall',
    pagePermission: [izaAccessPermission],
    auth: true,
  },
  {
    path: '/iza-call/accident-list',
    component: 'modules/izacall/pages/IzaCall',
    pagePermission: [izaAccessPermission],
    auth: true,
  },
  {
    path: '/iza-call/import-files',
    component: 'modules/izacall/pages/IzaCall',
    pagePermission: [izaAccessPermission],
    auth: true,
  },

  {
    path: '/hino-sos',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
    redirect: '/hino-sos/inquiry-list',
  },
  {
    path: '/hino-sos/inquiry-list',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
  },
  {
    path: '/hino-sos/inquiry-form',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [accessManagementClientPermission],
    auth: true,
  },
  {
    path: '/hino-sos/inquiry/:id',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
  },
  {
    path: '/hino-sos/create-special-client',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
  },
  {
    path: '/hino-sos/car-dealer-details',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
  },
  {
    path: '/hino-sos/car-dealer-search',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
  },
  {
    path: '/hino-sos/export-data',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
  },
  {
    path: '/hino-sos/shift-table',
    component: 'modules/hinosos/pages/HinoSos',
    pagePermission: [sosAccessPermission],
    auth: true,
  },
  {
    path: '/jmap',
    component: 'modules/jmap/pages/HinoMap',
    auth: true,
  },

  {
    path: '/hino-connect',
    component: 'modules/hinoconnect/pages/HinoConnect',
    pagePermission: [connectAccessPermission],
    auth: true,
    redirect: '/hino-connect/inquiry-list',
  },
  {
    path: '/hino-connect/inquiry-list',
    component: 'modules/hinoconnect/pages/HinoConnect',
    pagePermission: [connectAccessPermission],
    auth: true,
  },
  {
    path: '/hino-connect/inquiry-form',
    component: 'modules/hinoconnect/pages/HinoConnect',
    pagePermission: [accessManagementClientPermission],
    auth: true,
  },
  {
    path: '/hino-connect/inquiry/:id',
    component: 'modules/hinoconnect/pages/HinoConnect',
    pagePermission: [connectAccessPermission],
    auth: true,
  },
  {
    path: '/hino-connect/import-files',
    component: 'modules/hinoconnect/pages/HinoConnect',
    pagePermission: [accessManagementClientPermission],
    auth: true,
  },
  {
    path: '/hino-connect/file-viewer',
    component: 'modules/hinoconnect/pages/FileViewer',
    pagePermission: [connectAccessPermission],
    auth: true,
  },

  {
    path: '/user-management',
    component: 'modules/user/pages/UserManagement',
    pagePermission: [accessManagementUserPermission],
    auth: true,
  },

  {
    path: '/setting-info',
    component: 'modules/setting/pages/SettingManagement',
    pagePermission: [accessManagementUserPermission],
    auth: true,
    redirect: '/setting-info/ip-address',
  },
  {
    path: '/setting-info/ip-address',
    component: 'modules/setting/pages/SettingManagement',
    pagePermission: [accessManagementUserPermission],
    auth: true,
  },
  {
    path: '/setting-info/expressway',
    component: 'modules/setting/pages/SettingManagement',
    pagePermission: [accessManagementUserPermission],
    auth: true,
  },

  {
    path: '/management',
    component: 'modules/management/pages/Management',
    auth: true,
    redirect: '/management/tow-truck-company-list',
  },
  {
    path: '/management/carmaker-form',
    component: 'modules/management/pages/Management',
    auth: true,
  },
  {
    path: '/management/tow-truck-company-list',
    component: 'modules/management/pages/Management',
    auth: true,
  },
  {
    path: '/management/client-member-list',
    component: 'modules/management/pages/Management',
    auth: true,
  },
  {
    path: '/management/car-maker-list',
    component: 'modules/management/pages/Management',
    auth: true,
  },
  {
    path: `/management/car-maker-list/${1}`,
    component: 'modules/management/pages/Management',
    auth: true,
  },
  {
    path: '/management/client-list',
    component: 'modules/management/pages/Management',
    pagePermission: ['access client management'],
    auth: true,
  },
  {
    path: `/management/client-list/${1}`,
    component: 'modules/management/pages/Management',
    pagePermission: ['access client management'],
    auth: true,
  },
  {
    path: '/management/tow-truck-company-list',
    component: 'modules/management/pages/Management',
    auth: true,
  },

  {
    path: '/announcement',
    component: 'modules/announcement/pages/Announcement',
    auth: true,
  },

  {
    path: '/bill/',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
    redirect: '/bill/top',
  },
  {
    path: '/bill/top',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },
  {
    path: '/bill/pending',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },
  {
    path: '/bill/print-bill',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },
  {
    path: '/bill/cut-off',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },
  {
    path: '/bill/cut-off/:id',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },
  {
    path: '/bill/record',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },
  {
    path: '/bill/bill-details',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },
  {
    path: '/bill/remind',
    component: 'modules/bill/pages/Bill',
    pagePermission: [accessBillPagePermission],
    auth: true,
  },

  {
    path: '/tow-truck',
    component: 'modules/towtruck/pages/TowTruckCompany',
  },
  {
    path: '/tow-truck/request-list',
    component: 'modules/towtruck/pages/TowTruckCompany',
  },
  {
    path: '/tow-truck/report',
    component: 'modules/towtruck/pages/TowTruckCompany',
  },
  {
    path: '/tow-truck/report/:id',
    component: 'modules/towtruck/pages/TowTruckCompany',
  },
  {
    path: '/tow-truck/create-bill',
    component: 'modules/towtruck/pages/TowTruckCompany',
  },
  {
    path: '/tow-truck/create-bill/:id',
    component: 'modules/towtruck/pages/TowTruckCompany',
    auth: true,
  },
  {
    path: '/tow-truck/pay',
    component: 'modules/towtruck/pages/TowTruckCompany',
    auth: true,
  },

  {
    path: '/unauthorized',
    component: 'modules/auth/pages/Unauthorized',
    auth: true,
  },

  {
    path: '/export-data',
    component: 'modules/export/pages/HinoExport',
    auth: true,
  },
  {
    path: '/just-do-it/call-list',
    component: 'modules/justDoIt/pages/CallList',
    auth: true,
    pagePermission: [jusdoitAccessPermission],
  },
  {
    path: '/just-do-it/call-application',
    component: 'modules/justDoIt/pages/CallApplication',
    auth: true,
    pagePermission: [jusdoitAccessPermission],
  },
  {
    path: '/just-do-it/follow-call',
    component: 'modules/justDoIt/pages/FollowCall',
    auth: true,
    pagePermission: [jusdoitAccessPermission],
  },
  {
    path: '/just-do-it',
    component: 'modules/justDoIt/pages/CallList',
    auth: true,
    redirect: '/just-do-it/call-list',
    pagePermission: [jusdoitAccessPermission],
  },

  //all account
  {
    path: '/outbound',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/report',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [
      outboundCallInternalAccessPermission,
      outboundCallExternalAccessPermission,
    ],
    redirect: '/unauthorized',
  },

  //internal account
  {
    path: '/outbound/outbound-list',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/dm-shipping',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/various-basic-information',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/customer-date',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/outgoing-calls/:id',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/outgoing-call-history/:id/:inquiryId',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/after-outgoing-calls/:id',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/after-outgoing-call-history/:vehicleId/:inquiryId',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallInternalAccessPermission],
    redirect: '/unauthorized',
  },

  //external account
  {
    path: '/outbound/promote-warehouse-call-list',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallExternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/detail-promote-warehouse-call-list/:vehicleId',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallExternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/detail-promote-warehouse-call-list/:vehicleId/:inquiryId',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallExternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/after-call-list',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallExternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/maintenance-lease-call-list',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallExternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/after-outgoing-call-external/:vehicleId',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallExternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/outbound/after-outgoing-call-external/:vehicleId/:inquiryId',
    component: 'modules/outbound/pages/OutBound',
    auth: true,
    pagePermission: [outboundCallExternalAccessPermission],
    redirect: '/unauthorized',
  },
  {
    path: '/extraction',
    component: 'modules/extraction/pages/HinoExtraction',
    auth: true,
  },
];

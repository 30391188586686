import {
  AccountBox as AccountBoxIcon,
  Business as BusinessIcon,
  Dashboard as DashboardIcon,
  DialerSip as DialerSipIcon,
  Settings as SettingsIcon,
  Forum as ForumIcon,
  LibraryBooks as LibraryBooksIcon,
} from '@material-ui/icons';
import { translate } from 'utils/intl';
import React from 'react';
import TruckImage from '../../../components/TruckImage';
import {
  izaAccessPermission,
  sosAccessPermission,
  connectAccessPermission,
  outboundCallInternalAccessPermission,
  jusdoitAccessPermission,
  outboundCallExternalAccessPermission,
} from '../../../utils/constants';

export const route = (userRole, roles, permission = []) => {
  let hinoSosSubMenu = [
    {
      path: '/hino-sos/inquiry-list',
      label: translate('SOS reception list'),
    },
    {
      path: '/hino-sos/inquiry-form',
      label: translate('SOS application form'),
    },
    {
      path: '/hino-sos/create-special-client',
      label: translate('Browse dealer information'),
    },
  ];

  let hinoConnectSubMenu = [
    {
      path: '/hino-connect/inquiry-list',
      label: translate('CONNECT Inquiry List', {
        page: 'Hino Connect',
        tab: 'Inquiry List',
      }),
    },
    {
      path: '/hino-connect/inquiry-form',
      label: translate('CONNECT Receipt'),
    },
    {
      path: '/hino-connect/import-files',
      label: translate('CONNECT Import Files'),
    },
  ];

  let outboundSubMenu = [
    {
      path: '/outbound/outbound-list',
      label: translate('Name', { page: 'Outbound Call', tab: 'Outbound' }),
    },
    {
      path: '/outbound/dm-shipping',
      label: translate('Name', { page: 'Outbound Call', tab: 'DM Shipping' }),
    },
    {
      path: '/outbound/report',
      label: translate('Name', { page: 'Outbound Call', tab: 'Report' }),
    },
    {
      path: '/outbound/various-basic-information',
      label: translate('Name', {
        page: 'Outbound Call',
        tab: 'Various basic information',
      }),
    },
    {
      path: '/outbound/customer-date',
      label: translate('Name', { page: 'Outbound Call', tab: 'Customer date' }),
    },
  ];

  let justdoitSubMenu = [
    {
      path: '/just-do-it/call-list',
      label: translate('Call List Menu'),
    },
    {
      path: '/just-do-it/follow-call',
      label: translate('Follow Call Menu'),
    },
  ];

  let manegementSubMenu = [
    {
      path: '/management/tow-truck-company-list',
      label: translate('Tow Truck Company List'),
    },
    // {
    //   path: '/management/client-list',
    //   label: translate('Client List'),
    // },
    // {
    //   path: '/management/car-maker-list',
    //   label: translate('Car Maker List'),
    // },
  ];

  let settingSubMenu = [
    {
      path: '/setting-info/ip-address',
      label: translate('Hitoss Url'),
    },
    {
      path: '/setting-info/expressway',
      label: translate('Expressway upload'),
    },
  ];

  let outboundAccessPermission = outboundCallInternalAccessPermission;

  if (userRole === roles.carDealer || userRole === roles.carMaker) {
    hinoSosSubMenu = [
      {
        path: '/hino-sos/inquiry-list',
        label: translate('SOS reception list'),
      },
      {
        path: '/hino-sos/inquiry-form',
        label: translate('SOS application form'),
      },
      {
        path: '/hino-sos/export-data',
        label: translate('SOS Export Data'),
      },
      // {
      //   path: '/hino-sos/car-dealer-search',
      //   label: translate('Car Dealer Search'),
      // },
    ];

    outboundSubMenu = [
      {
        path: '/outbound/promote-warehouse-call-list',
        label: translate('Name', {
          page: 'Outbound Call',
          tab: 'Promote warehouse call list',
        }),
      },
      {
        path: '/outbound/after-call-list',
        label: translate('Name', {
          page: 'Outbound Call',
          tab: 'After call list',
        }),
      },
      {
        path: '/outbound/maintenance-lease-call-list',
        label: translate('Name', {
          page: 'Outbound Call',
          tab: 'Maintenance lease call list',
        }),
      },
      {
        path: '/outbound/report',
        label: translate('Name', { page: 'Outbound Call', tab: 'Report' }),
      },
    ];

    outboundAccessPermission = outboundCallExternalAccessPermission;
  }

  let pagesNeedPermission = [
    {
      title: translate('HINO SOS'),
      href: '/hino-sos',
      icon: <DialerSipIcon />,
      subMenu: hinoSosSubMenu,
      permission: sosAccessPermission,
    },
    {
      title: translate('HINO CONNECT'),
      href: '/hino-connect',
      icon: <BusinessIcon />,
      subMenu: hinoConnectSubMenu,
      permission: connectAccessPermission,
    },
    // {
    //   title: translate('OUTBOUND CALL'),
    //   href: '/outbound',
    //   icon: <ForumIcon />,
    //   subMenu: outboundSubMenu,
    //   permission: outboundAccessPermission,
    // },
    {
      title: translate('Just Do It'),
      href: '/just-do-it',
      icon: <TruckImage />,
      subMenu: justdoitSubMenu,
      permission: jusdoitAccessPermission,
    },
  ];

  pagesNeedPermission = pagesNeedPermission.filter(
    (item) => permission.indexOf(item.permission) !== -1,
  );

  let pages = [
    {
      title: translate('Main Menu'),
      href: '/',
      icon: <DashboardIcon />,
    },
    ...pagesNeedPermission,
    {
      title: translate('Management'),
      href: '/management',
      icon: <LibraryBooksIcon />,
      subMenu: manegementSubMenu,
    },
    {
      title: translate('User Management'),
      href: '/user-management',
      icon: <AccountBoxIcon />,
    },
    {
      title: translate('Setting Information'),
      href: '/setting-info',
      icon: <SettingsIcon />,
      subMenu: settingSubMenu,
    },
  ];

  if (userRole === roles.carDealer) {
    pages = pages.filter((page) => page.href !== '/user-management');
  }

  return pages;
};

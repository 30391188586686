/**
 * English-Japanese translation
 *
 * These are the words / labels used
 * in the application.
 */
const translation = {
  'Please select the target month.': '対象月を選択してください。',
  'Please select the target year.': '対象年を選択してください。',
  'Row per Page': '受付件数',
  Item: '件',
  'Total item': '全',
  'Business Category': '業務区分',
  'Business Line': '業務回線',
  'Business Introduction': '業務紹介',
  'Business Skills': '業務スキル',
  'Approval Authority': '承認権限',
  'Call Logs': '受付メモ',
  'Latest Call Logs': '最新の通話記録',
  'Confirm Action': 'アクションを確認',
  'Target Period': '対象期間',
  Output: '出力',
  'Are you sure you want to delete the selected rows?':
    '選択した行を削除してもよろしいですか？',
  'Are you sure you want to add this call log?':
    'このコールログを追加してもよろしいですか？',
  'Data Export': 'データ抽出',
  'HINO SOS': '日野SOS',
  'Incorrect username or password.':
    'ユーザーネームまたはパスワードが違います。',
  'IZA Call': 'IZAコール',
  'Main Menu': 'メインメニュー',
  'Phone Number': '電話番号',
  'Sign In': 'サインイン',
  'Sign Out': 'ログアウト',
  'Successfully Added': '追加されました',
  'Successfully Deleted': '削除しました',
  'User Management': 'ユーザー管理',
  'Setting Information': '各種基本情報',
  'Just Do It': 'JUST DO IT',
  'Call List Menu': '未手配一覧',
  'Follow Call': 'フォローコール',
  'Just Do It App': 'アプリ',
  'Hitoss Url': 'HITOSSUrl設定',
  'Ip Address': 'サーバIPアドレス',
  'Ip Button': '保存',
  'Please stop all access once to switch.':
    '切り替えを実施するにはすべてのアクセスを一度停止してください。',
  'Expressway upload': '高速道路データ更新',
  'Attach Expressway data file': '高速道路データ添付',
  Upload: '送信',
  Announcement: '周知回覧',
  Bill: '請求',
  Confirm: '確認する',
  Date: '日時',
  'Various Basic Information': '各種基本情報',
  Management: '管理',
  Outbound: 'アウトバウンド',
  OutboundItem: 'Outbound Call',
  JBaton: 'JBATON',
  Password: 'パスワード',
  'Old Password': '以前のパスワード',
  'New Password': '新しいパスワード',
  'Confirm Password': 'パスワードを認証する',
  Reponder: '対応者',
  Sales: '営業',
  Admit: '承認',
  'Well-known': '周知',
  Line: '業務回線',
  'Wrong Number': ' 間違い',
  Disconnected: '直切れ',
  Add: '連携',
  'Roadside Assistance System': 'イザ・コール',
  'Inquiry List': '受付内容一覧',
  'Inquiry Form': '受付票',
  'Accident List': '事故受付一覧',
  'Import Files': '業務資料登録',
  'HINO SOS Central Call Center': '日野SOS中央コールセンター',
  'SOS Inquiry List': 'SOSお問い合わせリスト',
  'SOS Inquiry Form': 'SOSお問い合わせフォーム',
  'Car Dealer Search': '販社情報 閲覧',
  'SOS Export Data': 'SOS対応データ',
  'Create Special Client': '自動CONNECT',
  'Car Dealer Details': 'SOS自動車ディーラーの詳細',
  'Seal 15': 'シール 15',
  Status: 'ステータス',
  Status_kanji: '状態',
  Username: 'ユーザー名',
  Save: '保存',
  'Username is required': 'ユーザー名が必要です。',
  'Password is required': 'パスワードが必要です。',
  SOS: 'SOS',
  IZA: 'IZA',
  'User ID': 'ユーザーID',
  Name: '名前',
  'Full Name': '氏名',
  'Kana Name': '氏名（カナ）',
  Role: '管理権限',
  'Other Permissions': 'その他の許可',
  Division: '区分',
  Permission: '許可',
  'Call Logs Page': 'コールログページ',
  'User Management Page': 'ユーザー管理',
  'User Name': 'ユーザー名',
  'Start Date': '開始日',
  'End Date': '終了日',
  'Contact Information': '連絡者情報',
  'Support window': 'サポート窓口',
  'Date of Incoming Call': '入電日',
  'Reception No.': '受付 No.',
  'Registration Number': '登録番号',
  'Company Name': '会社名',
  'Compensation Company Name': '自賠責会社名',
  'Requester Name': '依頼者名',
  'Main Sales Company': '主幹販売会社',
  'Query Classification': '問合せ分類',
  'Main Classification/Category': '大分類',
  'Classification/Category': '中分類',
  'Small Classification/Category': '小分類',
  'Major Category': '大分類を選択してください',
  'Middle Category': '中分類を選択してください',
  'Sub Category': '小分類を選択してください',
  'CC Status': 'CCステータス',
  'Correspondence Search': '対応者検索',
  'Conscientious Person': '対応者',
  'Escale Staff': 'エスカレ担当者',
  'Approval Required': '承認必要',
  'No Office': '事務所未対応',
  'Office Corresponding': '事務所対応中',
  Case: '件',
  'Correspondence Flow': '対応フロー',
  'Support Window': 'サポート窓口',
  'CONNECT Reception List': 'CONNECT 受付一覧',
  'CONNECT Receipt': 'CONNECT 受付票',
  'CONNECT Inquiry List': 'CONNECT 受付一覧',
  'CONNECT Inquiry Form': 'CONNECT 受付票',
  Receptionist: '受付者',
  'HINO CONNECT support window': 'HINO CONNECT サポート窓口',
  'Receiving Medium': '受信媒体',
  'Date and Time of Incoming Call': '入電日時',
  'Requester Information': '依頼者情報',
  'Client Category': '依頼者区分',
  'Client Name': '依頼者名',
  'Client Company Name': 'お客様名',
  'Client Company Name (Kana)': '依頼会社名（カナ）',
  'Caller Contact Information': '連絡先',
  'Sales Company': '販売会社',
  'New User': '新規追加',
  'User Management Form': 'ユーザー管理フォーム',
  None: '選択してください ',
  'Query History': '問合せ履歴',
  'User Registration Information (Automatic Reflection)':
    'ユーザー登録情報 (自動反映)',
  'HITOSS Company Name': 'HITOSS 会社名',
  'Main User ID': 'メインユーザーID',
  'User Code': '使用者コード',
  'Certification Code Issue Date': '認証コード発行日',
  'Base Name': '拠点名',
  'Name of Person in Charge of Registration': '登録担当者名',
  'Registered Person Email Address': '登録担当者メールアドレス',
  'Approval Sales Company': '承諾書記載販売会社',
  'User Information Status': 'ユーザー情報ステータス',
  'Certification CD': '認証CD',
  'Vehicle Information (Automatic Reflection)': '車両情報（自動反映）',
  'Exhaust Gas Symbol': '排ガス記号',
  'Chassis Number': '車台番号',
  'Registration Date': '登録年月日',
  'Presence or Absence of In-Vehicle Equipment': '車載機の有無',
  'Written Consent': '承諾書',
  Model: '型式',
  'Drive Recorder': 'ドライブレコーダー',
  'First Year Registration': '初年度登録',
  'Delivery Date': '納車日',
  'SIM State': 'SIM状態',
  'Vehicle Status': '車両ステータス',
  'Inquiry Information': '問合せ情報',
  'Inquiry Category': '問合せ分類',
  'Error Code': 'エラーコード',
  'Code List': 'コード一覧',
  Inquiry: '問合せ内容',
  ADD: '追加',
  EDIT: '修正',
  DELETE: '削除',
  UPDATE: '更新',
  INSERT: '挿入',
  VERIFICATION: '確認',
  POST: '投稿',
  'Desired Contact Date': '希望連絡日時',
  'Desired Contact Date and Time': '希望連絡日時',
  'Desired Answer Method': '希望回答方法',
  'Mail Management Number': 'メール管理番号',
  'Remaining Task': '残タスク',
  Complete: '完了',
  'List of Escale Charge': 'エスカレ担当一覧',
  'Call Center Status': 'コールセンターステータス',
  'Escalate Destination Status': 'エスカレ先ステータス',
  'Escalate Destination': 'エスカレ先',
  'OP Excale Fixed Form': 'OPエスカレ定型',
  'Completion Date and Time': '完了日時',
  'Excale Fixed Form': 'エスカレ定型',
  'HI-PREMA': 'HI-PREMAリンク',
  'Up (to receipt)': '↑ 上へ（受付票へ）',
  'Contact Category': '連絡者区分',
  'Contact Name': '連絡者名',
  'Company Information': '会社情報',
  'Company Name Kana': '会社名カナ',
  'Sales Office': '営業所',
  'Union Name': '組合名',
  'Vehicle Information': '車両情報',
  'Certificate Number': '証書番号',
  'Driver Information': '運転者情報',
  'Driver Name': '運転者名',
  'Driver Name Kana': '運転者名カナ',
  Sex: '性別',
  'License Number': '免許証番号',
  Birthday: '生年月日',
  Age: '年齢',
  'Accident Information': '事故情報',
  'Actual Date': '発生日',
  'Time of Occurrence': '発生時刻',
  'Place of Occurrence': '発生場所',
  'Accident Situation Summary': '事故状況概要',
  'Accident Details': '事故状況詳細',
  'Accident Situation Overview': '事故状況概要',
  'Accident Type': '事故種別',
  'Accident Situation Diagram': '事故状況図',
  Edit: '編集',
  Fix: '修正',
  'The Weather': '天候',
  'Report Police': '届出警察',
  'Own Car Information': '自車情報',
  'Repair Shop Name': '修理工場名',
  'Street Address': '住所',
  'Scheduled Goods Receipt': '入庫予定',
  'Driver Injury': '運転者ケガ',
  Injury: 'ケガ',
  'Injured Site': '負傷部位',
  'Injured Content': '負傷内容',
  'The Name of the Hospital': '病院名',
  'Hospital TEL': '病院TEL',
  'Partner Information': '相手情報',
  'Partner Classification': '相手区分',
  'Opponent Name': '相手氏名',
  'Opponent Name Kana': '相手氏名カナ',
  'Property Damage': '物損',
  Obstacle: '障害物',
  'Damage Status': '破損状況',
  'Car Name or Article Name': '車名または物品名',
  'Repair Shop TEL': '修理工場TEL',
  'Human Body': '人身',
  'Related Cases': '関連事案',
  'Accident Response Contact Information': '事故対応連絡先',
  'Contact Memo': '連絡メモ',
  'Receipt Slip Output': '受付票出力',
  'Policy Number': '証券番号',
  'Contractor Name': '契約者名',
  'Contractor TEL': '契約者TEL',
  'Contractor Address': '契約者住所',
  'Registration No': '登録番号',
  'Contractor Tel': '契約者 TEL',
  'Contract Search': '契約検索',
  'Contact Name.': '連絡者名',
  'Telephone Number': '電話番号(經理)',
  Type: '型式',
  'Contact Details': '連絡内容',
  Contact: '折返し連絡',
  'Return Contact': '折り返し連絡',
  'Desired Time and Date': '希望日時',
  'Contact Person Name': '連絡先氏名',
  'Contact Tel.': '連絡先TEL',
  'Operator Memo': 'オペレーターメモ',
  'Operator Memo (Connect)': 'オペレーターメモ（備考)',
  Draft: '下書き',
  'Report Output': '連絡票出力',
  'Related information': '関連情報',
  'Reception No': '受付 No.',
  'Driver or Administrator Name': 'ドライバー/管理者名',
  Message: '連絡事項',
  'Dispatch Staff': '出動スタッフ',
  Appreciation: '感謝',
  Claim: 'クレーム',
  'Major Classification': '大分類',
  'Average Classification': '中分類',
  'Minor Classification': '小分類',
  'Road Type': '道路種類',
  'Trouble Contents': 'トラブル内容',
  'Scheduled to Enter': '入予定',
  Expected: '発信予定',
  Unwritten: '未手記',
  'Main Line Not Arranged': '本線未手配',
  'See Remarks': '備考参照',
  Caller: '電話番号',
  'NEXCO not yet arranged': 'NEXCO 未手配',
  'Not arranged': '未手配',
  'Number of outgoing calls': '発信担当数',
  'Approval required': '承認必要',
  'No office support': '事務所未対応',
  'Under office': '事務所対応中',
  'Opportunity Search': '案件検索',
  New: '新規',
  'Download Summary': '集計出力',
  'New Client': '新規',
  'Dispatch Person In Charge': '発信担当者',
  'Scheduled Dispatch Time': '発信予定時間',
  'Customer Search': '顧客検索',
  'Customer Name': '顧客名',
  'Company Tel': 'TEL',
  'Road Service Inquiry': 'ロードサービス',
  'Tow Truck Accident Inquiry': '事故受付',
  'Application Form': '受付票',
  'Contact Form': '連絡票',
  'Down (to response history)': ' 下へ(対応経緯へ)',
  'Copy to accident receiver': '事故受へコピー',
  'Copy to contact form': '連絡票へコピー',
  'Final Updater': '最終更新者',
  'User Type': 'ユーザー種類',
  'Membership Number': '会員番号',
  'Driver Contact Information': 'ドライバー連絡先',
  'Administrator Name': '管理者名',
  'Administrator Contact': '管理者連絡先',
  'Caller Information': '入電者情報',
  'Caller Category': '入電者区分',
  'Credit Information Remark': '与信情報備考',
  'Trouble Situation': 'トラブル状況',
  'Trouble Point': 'トラブル発生地点',
  'Longitude or Latitude': '緯度／経度',
  'Injured Person': '負傷者',
  'Main Failure Category': 'トラブル内容-1',
  'Middle Failure Category': 'トラブル内容-2',
  'Small Failure Category': 'トラブル内容-3',
  'Mutual Insurace': '共済保険',
  'Accident Failure Type': '事故故障形態',
  'Permitted Delivery Destination Receipt': '搬送予定先入庫許可',
  'Target Work': '対象作業',
  'Planned Destination': '搬送予定先',
  Manufacturer: 'メーカー',
  'Other Manufacturer': 'その他メーカー',
  'Year(First Year of Registration)': '年式（初年度登録）',
  'Vehicle Type': '車種',
  'Maximum Loading Capacity': '最大積載量',
  'Vehicle Total Weight': '車両総重量',
  Cargo: '積荷',
  'Scheduled Transshipment': '積替予定',
  Milage: '走行距離',
  'Reporter Form': '報告形態',
  Transport: '搬送',
  'Dispatch Time': '出動時間',
  'Arrival Time': '現着時間',
  'Completion Time': '完了時間',
  'Wrecker Transport (empty Car)': 'レッカー搬送（空車）',
  'Wrecker Transport (actual Car)': 'レッカー搬送（実車）',
  'Driver Shaft': 'ドライブシャフト',
  'Business Trip Distance': '出張距離',
  'Tow Truck Transport (Empty Car)': 'レッカー搬送（空車）',
  'Tow Truck Transport (Actual Car)': 'レッカー搬送（実車）',
  'Drive Shaft': 'ドライブシャフト',
  'Propeller Shaft': 'プロペラシャフト',
  'Follow Up Call': 'フォローコール',
  'Follow Up Remarks': 'フォローコール備考',
  'Break Release': 'ブレーキ解除',
  'Double Tire (Outside)': 'ダブルタイヤ（外側）',
  'Double Tire(Inside)': 'ダブルタイヤ（内側）',
  'Single Tire': 'シングルタイヤ',
  'Air Adjustment': 'エアー調整',
  'Out of Gas': 'ガス欠',
  'Air Vent': 'エアー抜き',
  Inlock: 'インロック',
  Other: 'その他',
  'Safety Measure': '安全対策',
  'Additional Workers': '追加作業員',
  'Car Rear Guard': '後尾警戒車',
  'Waiting Time': '待機時間',
  'Work Details': '作業詳細',
  'Request Details': '依頼内容',
  'Fast Search': '高速検索',
  'J Staff': 'Jスタッフ地',
  'Reflected Ahead': '予定先反映',
  'Kanto Mutual Aid': '関東共済',
  'User Category': 'ユーザー区分',
  Report: '通報',
  Select: '選択',
  'Service Target Vehicle List': 'サービス対象車両リスト',
  'Update Date': '更新日',
  'Mutual Aid Rules': '共済ルール',
  'Escale Contact Person Search': 'エスカレ担当者検索',
  'Escale Status': 'エスカレステータス',
  'Matter Search': '案件検索',
  'Search for Projects': '案件検索',
  'Hire Date': '入社日',
  'Leave Date': '退社日',
  'SOS reception list': 'SOS受付一覧',
  'SOS application form': 'SOS 受付票',
  'Browse dealer information': '特殊ユーザー設定',
  'Enter dealer information': '販社情報 入力',
  'Scheduled Outgoing Time': '発信予定時間',
  'Reception Date and Time': '受付日時',
  'First Reporter': '第一入報者',
  Done: '作業完了',
  Authorizer: '承認者',
  'Please select a distributor': '販社を選択してください',
  'Please select a location': '拠点を選択してください',
  Unnecessary: '不要',
  Essential: '要',
  'Please enter TEL': 'TEL を入力してください。',
  'Please enter your name': '名前を入力してください',
  'Implementation Sales Company 2': '実施販売会社 2',
  'Customer Attributes': 'お客様属性',
  Customer: 'お客様',
  'HITOSS Rank': 'HITOSSランク',
  'HITOSS Vehicle Registration': 'HITOSS車両登録',
  'Maintenance Transaction': '整備取引',
  'Billing Name': '請求先名',
  'Billing Name Kana': '請求先名（カナ）',
  'Billing Code': '請求先コード',
  'HITOSS Information Remarks Column': 'HITOSS情報備考欄',
  'Company Name (Customer Name)': '会社名（お客様名）',
  Driver: 'ドライバー',
  Admin: '管理者',
  'Correspondence Classification': '対応区分',
  'Wrecker Staff Name': 'レッカースタッフ名',
  'Vehicle Movement Information': '車両動態情報',
  'In-Vehicle Communication Device': '車載通信機',
  'Information Acquisition Date': '情報取得日時',
  'Wrecker Listening Items': 'レッカー聴取項目',
  'Arrangement Type': '手配種別',
  'Transport Destination': '搬送先',
  Conveyance: '搬送ご対応',
  Transshipment: '積替',
  Lift: 'リフト',
  'Hope to Stay in the Car': '車中泊希望',
  'Pick up': '迎車',
  'Year (first year of registration)': '年式（初年度登録）',
  'Car Name (Manufacturer)': '車名（メーカー）',
  'Self-Propelled': '自走',
  'Loading Information': '積荷情報',
  'Scheduled Operation': '運行予定',
  'Cart Number': '台車番号',
  'Truck Manufacturer': '台車メーカー',
  'J Staff Search': 'Jスタッフ検索',
  'Request Location': '要請場所',
  'Request Category': '依頼区分',
  Truthu: 'トラシュー',
  'Work Completion Report': '作業完了報告',
  'Work Completion Report 1': '作業完了報告1',
  'Work Completion Report 2': '作業完了報告2',
  'Work Completion Report 3': '作業完了報告3',
  'J Support': 'J対応',
  'Dealer Support': '販社対応',
  Reporter: '報告者',
  'Report Form': '報告形態',
  'Departure Time': '出発時刻',
  'Site Arrival Time': '現場到着時刻',
  'Transport Distance': '出張距離',
  'Receipt Destination': '入庫先',
  'Corresponding Person Name': '対応者名',
  'Corresponding Person': '対応者',
  'Call Number': '電話番号',
  'Response Method (Required)': '対応方法（必須）',
  Diagnosis: '診断結果',
  'Correspondence Contents': '対応内容',
  'Response Results (Required)': '対応結果（必須）',
  Notices: '特記事項',
  'NEXCO Requested': 'NEXCO要請済',
  'Special User Registration': '特殊ユーザー登録',
  'Special User Search': '特殊ユーザー検索',
  'Special User List': '特殊ユーザー一覧',
  'Customer CD': '顧客CD',
  Remarks: '備考',
  Choice: '選択',
  Correction: '修正',
  Registration: '登録',
  'List Output': '一覧出力',
  'Former Sales Company': '元販売会社',
  'Implementation Sales Company': '実施販売会社',
  'Work Completion Required': '作業完了要',
  'Input Clear': '入力清',
  'Office Not Compatible': '事務所未対応',
  'Body Number': '車体番号',
  'Branch Office': '支局',
  Classification: '分類',
  Wonder: 'かな',
  'Specified Number': '指定番号',
  Wheelchair: '車両履歴',
  'Vehicle History': '車両履歴',
  'Attach Inquiry Form': '問合せフォーム添付',
  'Car Verification Attachment': '車検証添付',
  'Car Verification Certificate': '車検証',
  'Attachment of Materials': '資料添付',
  Reference: '参照',
  'Process of Correspondence': '対応経緯',
  'Response Request to the Office': '事務所への対応依頼',
  'To the next item': '次の案件へ',
  Approve: '承認',
  Outgoing: '発信',
  Receive: '受信',
  'Three Way Call': '三者間通話',
  Background: '経緯',
  Time: '時間',
  'Transmission/Reception Classification': '受発信区分',
  Content: '内容',
  'Dispatch Status': '出動状況',
  Attachment: '添付',
  'Last Updated': '最終更新日',
  'Last Updated By:': '最終更新者:',
  'Registration Number Search': '登録番号検索',
  'Company Name Search': '会社名検索',
  'Car Model Number Search': '車型車番検索',
  'Body Shape': '車体の形状',
  Request: '依頼',
  'Response Results': '対応結果',
  Approval: '承認',
  'Reception Sheet': '受付シート',
  'Down (to history)': '下へ（経緯へ）',
  'Up (to top)': '上へ（受付票へ）',
  'Former Big Sales Company': '開始対象販社',
  'End Sales Company': '終了対象販社',
  'Date and Time of Sending/Receiving': '受発信日時',
  'Transmission/Reception Classificaiont': '受発信区分',
  'Person in-Charge': '対応者',
  Opponent: '相手方',
  'Content Category': '内容区分',
  'Attachment Attach': '通録添付',
  'Attachment Photos': '写真貼付',
  'Copy to SOS': 'SOSへコピー',
  'HITOSS Search': 'HITOSS検索',
  Search: '検索',
  User: 'ユーザー',
  Vehicle: '車両履歴',
  'Register/Modify': '登録 / 修正',
  'Reception Output': '受付出力',
  'Incoming Call Date': '入電日',
  Reception: '受付',
  'Vehicle No.': '車両番号',
  'Driver or Admin Name': 'ドライバー／管理者名',
  'Phone Number Search': '電話番号検索',
  Phone: '電話',
  'Mutual Aid': '共済',
  'Status Type': 'ステータス',
  'Recipient Search': '受付者検索',
  'Inquiry Search': '案件検索',
  'Client Search': '顧客検索',
  'Various Logins and Settings': '各種登録・設定',
  Distinguish: '区分',
  Change: '変更履歴',
  'Turn Table': '当番表',
  'Support Basic Rules': '対応基本ルール',
  Credit: '与信',
  'Rank Confirmation': 'ランク確認',
  'Company Name Credit': '会社名与信',
  'Arrangement Completed': '手配完了',
  'C Support': 'C対応',
  'Specific Failure Report': '特定故障報告',
  Accident: '事故',
  'Vehicle Fire': '車両火災',
  'On the Ground J-Compatible Main Line': '地上J対応本線上',
  'Spare Tire Replacement': 'スペアタイヤ交換',
  Jumping: 'ジャンピング',
  'Other than Ground J-Compatible Main Line': '地上J対応本線以外',
  Remark: '備考',
  'Address Kana': '住所カナ',
  'Responsible Area': '担当地域',
  'Business Day': '営業日',
  'Monday to Friday': '月～金',
  Soil: '土',
  Holidays: '日祝',
  'Time Zone': '時間帯',
  'Weekdays at Night': '夜間平日',
  'Night Holiday': '夜間休日',
  'Daytime Holiday': '日中休日',
  'Specific Day': '特定日',
  'Change Password': 'パスワードを変更する',
  'Last Update': '最終更新',
  'Requested Company Name': '依頼会社名',
  'Escalé Destination': 'エスカレ先',
  'Escalate Charge': 'エスカレ担当',
  'Date To': '日付',
  'CONNECT Import Files': 'CONNECT業務資料登録',
  FAQ: 'よくある質問',
  Export: '出力',
  'Insurance Expiration Information': '保険満了案内',
  Category: '区分',
  'Supported Types': '対応種類',
  Rank: 'ランク',
  Code: 'コード',
  'Company Profile': '会社概要',
  Headquarters: '本社',
  Base: '拠点',
  'Document Destination': '書類送付先',
  Pricing: '料金設定',
  'Automatic Numbering': '自動付番',
  Position: '役職',
  'Representative Name Kana': '代表者名カナ',
  'Representative Name': '代表者名',
  'Postal Code': '郵便番号',
  'Anti-login': '仮登録',
  'Email Address': 'e-mailアドレス',
  'Non-affiliated Staff': '提携外スタッフ',
  'CC Memo': 'CCメモ',
  'How to join': '加入方法',
  'Number of Employees': '従業員数',
  'Insurance Company Name': '保険会社名',
  'Insurance Expiration Period': '保険満了期間',
  'Expressway Agreement': '高速道路協定',
  'Permission Truck': '一般貨物自動車運送事業の許可',
  'Paid Transportation Permission': '有償運送許可',
  'Automobile Rescuer Qualification': '自動車救援士資格',
  Riding: '乗用',
  'Small Truck': '小型トラック',
  'Medium Truck': '中型トラック',
  'Heavy Truck': '大型トラック',
  'Vehicle Parking Service': '車両の駐車サービス',
  'Substitute and Delivery Arrangement': '代車・配送手配',
  'Specifying JAF': 'JAFの指定',
  Specified: '指定',
  Authentication: '認証',
  'Maintenance Factory': '整備工場',
  'Sheet Metal Painting Factory Workers': '板金塗装工場工員数',
  'Frame Correction Machine': 'フレーム修正機',
  'Painting Booth': '塗装ブース',
  'Deletion Date': '削除年月日',
  'Please enter the reason for deletion': '削除理由を入力してください',
  'Primary Registration': '一次登録',
  'First Registration Date': '初回登録日',
  'Last Updated By': '最終更新者',
  'Same as Company Profile': '会社概要と同じ',
  'Base Name Kana': '拠点名カナ',
  'J Map Reflection': 'J地図反映',
  'Attendance Registration': '出勤時間登録',
  'Dispatch Request Number': '出動要請番号',
  'Night Request Number': '夜間要請番号',
  'Waiting Number': '待機人数',
  'FUSO Outsourcing': 'ふそう外注',
  'SOS Outsourcing': 'SOS外注',
  'IZA Call Priority Area': 'イザコール 優先エリア',
  'Priority Area for J': 'J対応 優先エリア',
  'SOS Outsourcing Priority Area': 'SOS外注 優先エリア',
  'FUSO Outsourcing Priority Area': 'ふそう外注 優先エリア',
  'Other Outsourcing Priority Area': 'その他外注 優先エリア',
  'Remarks (Memo)': '備考（メモ）',
  'Available Work': '対応可能作業',
  'Extra Large Bus (Total Weight 25T or More)': '特大・バス（総重量25t以上）',
  'Wrecker/Loading': 'レッカー作業／積荷',
  'Spare Tire/Exchange': 'スペアタイヤ／交換',
  'B/T Jumping': 'B/Tジャンピング',
  'Gas Shortage/Gasoline Replenishment': 'ガス欠／ガソリン補充',
  'Wrecker/Empty Load': 'レッカー作業／空荷',
  'Spare Tire/Recombination': 'スペアタイヤ／組替',
  'First Aid Repair': '応急修理',
  'Out of Gas/Replenish Area': 'ガス欠／尿素補充',
  'Vehicle Custody': '車両預かり',
  'Large Vehicle (Total Weight 8T or more and less than 25T)':
    '大型車（総重量8t以上25t未満）',
  'Medium-sized Car (Total Weight 4t or more and less than 9t)':
    '中型車（総重量4t以上9t未満）',
  'Small Car (total weight less than 4t)': '小型車（総重量4t未満）',
  'Passenger Car': '乗用車',
  'Tire Handling (less than medium size)': 'タイヤ取扱（中型以下）',
  'Tools / Equipment / Parts': '工具・器具・部品',
  'New Tire': '新品タイヤ',
  'Used Tire': '中古タイヤ',
  'Spare Tire for Rental': '貸出用スぺヤタイヤ',
  'Tire Handling (large)': 'タイヤ取扱（大型）',
  'Tire Tool (medium or smaller)': 'タイヤ工具（中型以下）',
  'Air Compressor': 'エアーコンプレッサー',
  'Impact Wrench': 'インパクトレンチ',
  'Torque Wrench': 'トルクレンチ',
  Boost: '倍力',
  'Tire Tool (large)': 'タイヤ工具（大型）',
  'Jumping Equipment and Tools': 'ジャンピング器具・工具',
  'Voltage Tester': '電圧テスター',
  'Charger 12V': 'チャージャー12V',
  'Charger 24V': 'チャージャー24V',
  'Battery Replenisher': 'バッテリー補充液',
  'Unlocking Tools': '鍵開け工具',
  Picking: 'ピッキング',
  'Air Mat': 'エアーマット',
  'CC Common Issues': 'CC共有事項',
  'Dispatch Request Exclusion Period': '出動要請除外期間',
  'Call Center Memo': 'コールセンターメモ',
  Payee: '振込先',
  'Fee Acceptance': '手数料了承',
  'Bank Name': '銀行名',
  'Bank Code': '銀行コード',
  'Branch Name': '支店名',
  'Branch Code': '支店コード',
  'Account Number': '口座番号',
  'Account Name': '口座名',
  'Please enter in single-byte kana': '半角カナで入力してください',
  'Same as Head Office': '本社と同じ',
  'Not Affiliated': '提携外',
  List: '一覧',
  'MANAGEMENT Staff Information': 'MANAGEMENT スタッフ情報',
  'MANAGEMENT Member Information': 'MANAGEMENT 会員情報',
  'MANAGEMENT Client Information': 'MANAGEMENT クライアント情報',
  'Hot Spot Name': '拠点名',
  'Department Name': '部署名',
  'Deleted Data': '削除データ',
  'Name of Person in Charge': '担当者氏名',
  'Free Word': 'フリーワード',
  'Data Output': 'データ出力',
  '00 Hino (Headquarters)': '00日野(本社)',
  '00 Branch': '00支店',
  'Basic Information': '基本情報',
  'Office Memo': '事務所メモ',
  'Sales Company CD': '販社CD',
  'Hot Spot CD': '拠点CD',
  'J Reflection of Map': 'J地図反映',
  'FAX Number': 'FAX番号',
  'Same as Basic Information': '基本情報と同じ',
  'Billing Rules': '請求ルール',
  'Responsible Business': '担当営業',
  'Responsible Business 1': '担当営業1',
  'Responsible Business 2': '担当営業2',
  'Work Charge Category': '作業料金区分',
  'Closing Date': '締日',
  'Addressed to Company': '会社宛',
  'Person in Charge': '担当者知',
  'Customer Case': '顧客後案',
  Rely: '依頼',
  'JNS Correspondence': 'JNS 対応',
  'Corresponding Results': '対応結果',
  'Vehicle Dynamics Information': '車輌動態情報',
  'In-vehicle Communication Device': '車載通信機',
  'Car Number': '車番',
  Tokyo: '東京',
  Fukuoka: '福岡',
  Total: '合計',
  'Incoming and Outgoing Calls': '受発信',
  'Automatic Reflection': '自動反映',
  'Corresponding Category': '対応区分',
  'A Category': 'A対応',
  'B Category': 'B対応',
  'C Category': 'C対応',
  'J Category': 'J対応',
  'S Customer': 'S客',
  'N Customer': 'N客',
  'B Customer': 'B客',
  'G Customer': 'G客',
  Unclear: '不明',
  'Attendance Staff': '出勤スタッフ',
  'Request Contents': '要請内容',
  'Specific Failure': '特定故障',
  'Self-propelled Accident': '自走不可事故',
  Wrecker: 'レッカー',
  'Other Manufacturers': '他メーカー',
  'Major Issues': '重大案件',
  'Inquiry or Other': '問合せ / その他',
  Inquiry2: '問合せ',
  'Escaling Request': 'エスカレ依頼',
  'Escale Answer': 'エスカレ回答',
  Answer: '回答',
  'Scheduled Incoming Calls': '入電予定',
  'Enter Folk Song': '?月入力',
  'Coverage Month': '保険満了月',
  'Before Specification': '指定より以前',
  'Designation Month': '指定当月',
  'All Duty Exemption': '全任免行',
  'Designation Completion Staff': 'スタッフ指定完行',
  'Enter Code': 'コードを入力',
  'Exclusion Period': '出動変更除外期間',
  'Reference Column': '備考欄参照',
  General: '一般',
  'Cancel All': '全解除',
  'Select All': '全選択',
  'Permission for General Truck Transportation Business':
    '一般貨物自動車運動事業の許可',
  'Paid Transportation Permit': '有償運送許可証',
  'Specify AF': 'AFの指定',
  'Sheet Metal Painting Factory': '板金塗装工場 行員数',
  'Head Office Information': '本社情報',
  'Site Information': '拠点情報',
  'Contract Name': '担当者名',
  'Contract Name Kana': '担当者名カナ',
  'Item Selection': '項目選択',
  Return: '戻る',
  Department: '部署',
  'Responsible Fax Number': '担当 FAX 番号',
  Label: 'ラベル',
  'Add To': '追加',
  Enomoto: '榎本',
  'CSV Import': 'CSV取込',
  'Tow Truck Company List': 'レッカー車会社一覧',
  'Client List': 'クライアントリスト',
  'Car Maker List': 'カーメーカー一覧',
  'OUT BOUND': 'アウトバウンド',
  'Follow-up After Vehicle Inspection Solicitation': '車検勧誘・入庫後フォロー',
  'Follow-up Call': 'フォローコール',
  'Data Acquisition': 'データ取込み',
  'Business Sales Company Base': '業務販社/拠点',
  'Car End Date': '車検満了日',
  'Last Vehicle Inspection Date': '前回車検入庫日',
  'Vehicle Inspection Expiration': '車検満了日',
  'Last Call Date': '最終発信日時',
  'Wakayama Hino': '和歌山日野',
  'Call Required': '要発信',
  'Tokyo Hino': '東京日野',
  'Announcement Creation': '周知作成',
  'Announcement Date': '周知日',
  'Announcement Date and Time': '日時',
  'Announcement Business Category': '業務区分',
  Title: 'タイトル',
  'Announcement Expiration Date': '閲覧期限切れ',
  Contributor: '投稿者',
  Importance: '重要度',
  'Rebit Announcement': 'リピート周知',
  'Office Representative': '事務所担当者',
  'Day Time': '日時',
  'Announcement Status': '閲覧状況',
  Office: '事務所担当',
  'Others (HR General Affairs / Internal Announcement)':
    'その他(人事総務・社内回覧)',
  'Staff information (Information on staff dispatch status of basic information is included)':
    'スタッフ情報 (基本情報のスタッフ出動状況の周知が入る)',
  'Business Awareness': '業務周知',
  'General Knowledge': '一般周知',
  'Link Destination': 'リンク先',
  Repeat: 'リビート',
  'Announcement Deadline': '閲覧期限',
  'Known Elephant': '周知対象',
  Confirmed: '確認しました',
  'Confirmed (Answer)': '確認しました(回答する)',
  'Response Form': '回答方法',
  'Response Status': '回答状況',
  'Tokyo Center': '東京センター',
  'Fukuoka Center': '福岡センター',
  'The Office': '事務所',
  'Close Up': '閉じる',
  Undecided: '未定',
  'Free Comment': 'フリーコメット',
  'Prev Month': '前月',
  'Remarks for Sales Company': '販社対応備考',
  'Remarks for J': 'J対応備考',
  'Business Sales Company Points': '業務販社拠点',
  'Shinsayama Branch': '新狭山支店',
  Escale: 'エスカル',
  'Fujii Transportation': '藤井運送',
  Fujiiunsou: 'フジイウンソウ',
  'Customer Code': '顧客コード',
  'Customer Representative': 'お客様担当者',
  'Hachioji City, Tokyo': '東京都八王子市○○',
  'CC Remarks': 'CC 備考',
  'Recall After 29th': '29 日以降再架電',
  'Vehicle List and Results': '保有車両一覧と結果',
  'Car Dealer No': '車商No',
  'Last Call Time': '前回発信日時',
  'Decision Contact': '決定権者接触',
  Guide: '案内',
  'Personnel Cooperation': '担当者連携',
  'Tapping Receipt Destination': '聴取入庫先',
  Caution: '要注意',
  Adachi: '足立',
  Or: 'か',
  HINO: '日野',
  Tanaka: '田中',
  Uemura: '上村',
  Kikukawa: '菊川',
  Times: '回',
  'Hope to Enter the Vehicle': '車檢入庫希望',
  'Wish to Trade': '取引希望',
  'Call Date and Time': '発信日時',
  'Phone Outlet': '電話口担当者',
  'Satoshi Sato': '佐藤樣',
  'Tanaka Kei': '田中樣',
  'Result Details': '結果詳細',
  'Due to absence of the person in charge, Mr. Tanaka Hope.':
    '担当者の田中様不在のため、来週以降架電希望',
  'Dont call me anymore !!': 'もう電話してくるな!! とご立腹',
  'Internal Collaboration Details': '社内連携内容',
  'The State of Angry that no Telephone is Required': '電話不要とご立腹の様子',
  'How Nice': 'いいね',
  'Correspondence History': '対応経緯',
  Sequence: '経緯',
  'Date Time': '時間',
  Calls: '受発信区分',
  Attendance: '出勤状況',
  Reaction: 'リアクション',
  Thanks: '感謝',
  Partner: '相手先',
  Register: '登録',
  'Dispatch status': '出動状況',
  'Staff No.': 'スタッフ No.',
  'Staff name': 'スタッフ名',
  'Staff contact': 'スタッフ連絡先',
  Branch: '支部',
  'Request category': '依頼区分',
  'Person in charge impression': '担当者印象',
  'Tow truck transport': 'レッカー搬送',
  Good: '良い',
  Average: '普通',
  Bad: '悪い',
  'Cancellation fee guide': 'キャンセル料案内',
  Paid: '済',
  Unpaid: '未済',
  'Cancel before dispatch': '出動前キャンセル',
  'Cancellation before dispatch arrival': '出動後現場到着前キャンセル',
  'Cancellation after dispatch arrival': '出動後現場到着後キャンセル',
  Close: '閉じる',
  'Reason for cancellation': 'キャンセル理由',
  Progress: '経過',
  'Planned deposit date': '入金予定日',
  'Next item': '次の案件へ',
  'Invoice screen': '請求書画面',
  'Request details': '依頼内容',
  'Price inquiry': '料金問合せ',
  'In Progress': '対応中',
  Completed: '完了',
  'Required Approval': '承認必要有無',
  Incoming: '受信',
  'Up (to the reception slip)': '上へ（受付票へ）',
  'During conversation': '示談中',
  'Payment request': '入金願い',
  Reminder: '督促状',
  'Proof of concept': '内容証明',
  'Response result': '対応結果',
  Shipment: '積荷予定',
  Kana: '仮名',
  'Select Category 1': '区分①を選択してください',
  'Select Category 2': '区分②を選択してください',
  'Please select prefecture': '都道府県を選択してください',
  'Please enter your address': '住所を入力してください',
  'Please enter your contact': '連絡先を入力してください',
  Width: '幅',
  Length: '長さ',
  Height: '高さ',
  'Applicable Case': '該当案件',
  Incomplete: '未完了',
  'Reception Memo System': '受付メモシステム',
  'Business Sales Point': '業務販社点',
  'Straight Cut': '直切れ',
  Mistake: '間違い',
  'Ikebukuro Collaboration': '池袋連携',
  'Line Type': '回線種別',
  'Customer Line': 'お客様回線',
  'Hino SOS Shinichi Kaname': '日野 SOS 要発信一覽',
  'Search for the Person in Charge': '担当者検索',
  'Vehicle Number': '車両No',
  'Delete Data': 'データを削除',
  'Not Accepted': '受け入れられません',
  'Vehicle List Output': '車両一覧出力',
  'Join Document Output': '加入書類出力',
  'Member Information': '会員情報',
  'Member offices': '会員事務所',
  Address: '住所',
  Email: 'Eメール',
  Email2: 'メール',
  'Vehicle Registration': '車両登録',
  'How To Join': '参加方法',
  'Name of Representative': '代表者の名前',
  'Date Join': '参加日',
  'Mutual Aid Code': '相互扶助コード',
  'Expiration Date': '有効期限',
  'Acceptability History': '受け入れ可能性の履歴',
  'Delete Date': '削除日',
  'Call center memo': 'コールセンターメモ',
  'Office memo': 'オフィスメモ',
  'Enter reason for deletion': '削除理由を入力してください',
  'Customer Name (Kana)': 'お客様名（かな）',
  'Please select category 1': 'カテゴリーを選択してください1',
  'Please select category 2': 'カテゴリーを選択してください2',
  'Billing Information': '課金情報',
  'Tightening Work': '締め付け作業',
  Payment: '支払い',
  'Issue Slip': '伝票の発行',
  'Delivery Deadline': '納期',
  Biller: 'ビラー',
  'Registered Vehicle': '登録車両',
  'Vehicle Weight': '車両重量',
  'Loading Capacity': '積載能力',
  'Vehicle Type 1': '車両タイプ1',
  'Total Vehicle Weight': '車両総重量',
  'Vehicle Loading Capacity': '車両積載量',
  'Car type 1': '車種1',
  'Car type 2': '車種2',
  'Please select': '選んでください',
  Clear: 'クリア',
  'Vehicle Registration Fee Payment Record': '車両登録料入金記録',
  'Sales Representative': '営業担当者',
  'Sales Representative 1': '営業担当者1',
  'Sales Representative 2': '営業担当者2',
  'Vehicle Registration Fee': '車両登録料',
  'Work Fee Classification': '作業費の分類',
  'Last Update:': '最後の更新',
  'Last Update By:': '最終更新者',
  Location: 'ロケーション',
  'Client Member List': 'クライアント会員リスト',
  Kind: '種類',
  'Dispatch Staff Name': '出動スタッフ名',
  'Completion List': '作成完了一覧',
  'Caller Name': '発信者名',
  'User Information': 'ユーザー情報',
  'User Information Category': 'ユーザー情報カテゴリ',
  'User Information Sub Category': 'ユーザー情報サブカテゴリ',
  'Company Kana': '会社名カナ',
  'Company Street Address': '会社の住所',
  'Company Phone Number': '電話番号',
  'Company Membership Number': '会員番号',
  'Company Driver Name': 'ドライバー名',
  'Company Driver Contact Information': 'ドライバー連絡先',
  'Company Administrator Name': '管理者名',
  'Company Administrator Contact': '管理者連絡先',
  'Report Work Details': '作業詳細',
  'Report Type': '型式',
  'Report Transport': '搬送',
  'Report Transportation Destination Name': '搬送先',
  'Report Dispatch Time': '出動時間',
  'Report Arrival Time': '現着時間',
  'Report Completion Time': '完了時間',
  'Report Business Trip Distance': '出張距離',
  'Report Actual Empty Car Distance': 'レッカー搬送（空車）',
  'Report Actual Vehicle Distance': 'レッカー搬送（実車）',
  'Report Drive Shaft': 'ドライブシャフト',
  'Report Propeller Shaft': 'プロペラシャフト',
  'Report Follow Up Call': 'フォローコール',
  'Report Follow Up Remarks': 'フォローコール備考',
  'Report Break Release Place': 'ブレーキ解除',
  'Report Double Tire In': 'ダブルタイヤ（内側）',
  'Report Double Tire Out': 'ダブルタイヤ（外側）',
  'Report Single Tire': 'シングルタイヤ',
  'Report Air Adjustment': 'エアー調整',
  'Report Out of Gas': 'ガス欠',
  'Report Air Vent': 'エアー抜き',
  'Report Inlock': 'インロック',
  Others: 'その他',
  'Report Safety Measure': '安全対策',
  'Report Additional Workers': '追加作業員',
  'Report Car Rear Guard': '後尾警戒車',
  'Report Waiting Time': '待機時間',
  'Report Correspondence Contents': '対応内容',
  'Vehicle Model Year': '年式（初年度登録）',
  'Vehicle Model': '型式',
  'Vehicle Chassis Number': '車台番号',
  'Vehicle Maximum Loading Capacity': '最大積載量',
  'Vehicle Body Shape': '車体の形状',
  'Vehicle Width': '幅',
  'Vehicle Length': '長さ',
  'Vehicle Height': '高さ',
  'Vehicle Manufacturer': 'メーカー',
  'Vehicle Other Manufacturers': '他メーカー',
  'Vehicle Cargo': '積荷',
  'Vehicle Scheduled Shipment': '車両予定出荷',
  'Vehicle Scheduled Operation': '運行予定',
  'Vehicle Milage': '走行距離',
  'Trouble Longitude or Latitude': '緯度／経度',
  'Trouble Injured Person': '負傷者',
  'Tow Truck Staff Contact': 'スタッフ連絡先',
  'Tow Truck Staff Name': 'レッカースタッフ名 ',
  'Pending Purchase List': '仕入待ち一覧',
  'Waiting for Purchases': '仕入待ち件数',
  'Oldest Request Date': '最も古い依頼日',
  Selection: '選',
  'Requested Date': '依頼日',
  'Payment Status': '入金状況',
  'Amount of Money': '金額',
  'Number of Billing Cases': '請求案件数',
  'Deposit Processing': '入金処理',
  'Billing List': '請求一覧',
  'Total Bill': '請求計',
  'Payment Date': '入金日',
  'Deposit Account': '入金口座',
  'Deposit Amount': '入金額',
  Fee: '手数料',
  Balance: '残額',
  'Bulk Deposit': '一括入金',
  Cancel: 'キャンセル',
  'Payment Month': '支払月',
  'Paymont Status': '支払状況',
  'Staff Name Kana': 'スタッフ名（カナ）',
  'Number of Request': '請求案件数',
  'Number of Payments': '支払案件数',
  'Payment List': '支払一覧',
  'Payment Processing': '支払処理',
  'Lump Sum Payment': '一括支払',
  'Date of Payment': '支払日',
  'Purchase Amount': '仕入金額',
  'Cut Off': '締め',
  Print: '印刷',
  'CSV Export': 'CSV出力',
  'Total Fee': '合計金額',
  'Planned Deposit Date': '入金予定日',
  Situation: '状況',
  'History Record': '経緯記録',
  'Sales Amount': '売上金額',
  Author: '作成者',
  'Total Number of Cases': '総件数',
  'Awaiting Purchase': '仕入待ち',
  'Next Month Carry-Over Project': '翌月繰越案件',
  'Invoice Status': '請求書ステータス',
  'Staff Name': 'スタッフ名',
  Invoice: '請　　求　　書',
  'Billing Subject': '請求件名',
  'Total Number': '合計件数',
  'Unit Price': '単価',
  Quantity: '数量',
  'Total Amount Charge': '請求金額合計',
  'Bank of Transfer': '振込先銀行',
  'Perform Tightening Work': '締め作業実行',
  'Scheduled incoming calls': '入電予定',
  'Call schedule': '発信予定',
  'see remarks': '備考参照',
  Calculation: '計算',
  'Manufacturer or Model': 'メーカー/車種',
  'Number of Days Elapsed': '経過日数',
  'Not Yet Started': '請求登録待ち2件',
  Pending: '請求申請中1件',
  'Item Information': '案件情報',
  'Customer Information': 'お客様情報',
  'Exhaust Gas-Model': '排ガス-型式',
  'Work Completion Time': '作業完了時間',
  'Less Than 30%': '30%未満',
  '30% or More': '30%以上',
  Overloading: '過積載',
  'Secondary Transport': '二次搬送',
  Withdrawal: '引き出し・引き起こし',
  'Tire Exchange': 'タイヤ交換',
  'Battery Jumping': 'バッテリージャンピング',
  'Please Select Your Work. (Multiple Choices Allowed)(Required)':
    '作業内容を選択してください。（複数選択可）（必須）',
  'In case of cancellation, please select the following.':
    'キャンセルの場合は下記も選択お願いします。',
  'Cancellation before arrival at the site after dispatch':
    '出動後現場到着前キャンセル',
  'Cancellation after arrival at the site': '現場到着後キャンセル',
  'View Price List': '料金表閲覧',
  'Work Fee': '作業料金',
  'Work Content': '作業内容',
  Postscript: '追記',
  'Included in Basic Dispatch Fee': '基本出動料に含む',
  'Staff Work Fee': 'スタッフ作業料金',
  'Basic Dispatch Fee': '基本出動料金',
  'Forwarding Distance': '回送距離',
  'Empty Car': '空車',
  '30% or more of actual vehicle / load': '実車/積荷30%以上',
  'Wrecking Work': 'レッキング作業',
  'On-site processing costs (removal of rear shaft':
    '現場処理費（リアシャフト取外し',
  'On-site processing costs (manual brake release':
    '現場処理費（手動ブレーキ解除',
  'Please Enter a Reason': '理由を入力してください',
  'Manual input field (special work)': '手入力欄（特殊作業）',
  'Extra Charge': '割増料金',
  SubTotal: '小計',
  'Outside Tax': '外税',
  'High Speed Fee': '高速代',
  'Fuel Cost': '燃料代',
  'Toll Fee': '通行料',
  'Round Trip': '往復',
  Going: '行き',
  'Site Photo': '現場写真',
  'Temporary Saved': '一時保存',
  'Claim Application': '請求申請',
  'Correction Work Fee': '訂正作業料金',
  'Waiting for Deposit': '入金待ち',
  Deposited: '入金済み',
  'Detail Printing': '明細印刷',
  'Request Date and Time': '依頼日時',
  'Payment Date (Scheduled Date)': '入金日（予定日）',
  Printing: '印刷',
  'TOW TRUCK': '牽引車',
  'Main Power/Model': 'メ一力一/車種',
  'Staff Request Base': 'スタッフ依頼拠',
  'Transportation Co., Ltd.': '運輸(株)',
  'Shinagawa 100 A1111': ' 品川100あ1111',
  'Hino/Medium': '日野/中型',
  Reflect: '反映',
  Communication: '連絡事項',
  'Car Inspection': '車検業務',
  'Outgoing Calls': '発信回数',
  Types: '種別',
  'Trouble After Delivery': '納車後不具合',
  History: '履歴',
  'Person in-Charge Cooperation': '担当者連携',
  'Not Dialed': '未発信',
  'Inspection Solicitation': '和歌山日野',
  After: 'アフター',
  'Date and Time': '依頼日時',
  'Project No': '案件No',
  'Manufacturer/Model': 'メーカー/車種',
  'Staff Request': 'スタッフ依頼',
  'Hino/Large': '日野/大型',
  'Final Report': '完了報告',
  'Request Information': '要請情報',
  'Create Bill': '請求書を作成',
  'Confirm Payment': '支払いを確認',
  'Transportation㈱': '運送㈱',
  'Car Model': '車種',
  'Exhaust Gas Model': '排ガス-型式',
  'Yes/Wood 5t': '有/木材5t',
  Yes: '有',
  Nothing: '無',
  No: '無',
  'Attendance Time': '出勤時間',
  'Land Destination': '地送先',
  'Please Enter A Name': '名称を入力してください',
  'Please Enter An Address': '住所を入力してください',
  'Please Enter A Contact': '連絡先を入力してください',
  'Tow Truck Transport (empty)': 'レッカー搬送(空車)',
  'Tow Truck Transport (real vehicle)': 'レッカー搬送(実車)',
  'Remove Only': '外しのみ',
  'Final Tightening': '本締め',
  'Temporary Filling': '仮詰め',
  'Without Removal': '外しなし',
  Book: '本',
  'Brake Release': 'ブレーキ解除',
  Places: 'ヶ所',
  'After Dispatch and Before Arrival at the Site': '出動後現場到着前キャン',
  'After Dispatch': '出動後現場到着後キャン',
  'Withdraw, Cause': '引き出し、引き起こし',
  'Double Tire (outside)': 'ダブルタイヤ(外側)',
  'Double Tire (inside)': 'ダブルタイヤ(内側)',
  'Air Bleed': 'エアー抜き',
  'Safety Measures': '安全対策',
  'Report Completed': '報告完了',
  Size: '寸法',
  'Exhaust Gas/Model': '排ガス/型式',
  'Loading/Unloading': '積荷有無',
  'Cargo Information': '積荷情報',
  'Trouble Place': 'トラブル場所',
  Highway: '高速道路',
  'Trouble Classification': 'トラブル区分',
  'Small Item': '小項目',
  'Trouble Details': 'トラブル詳細',
  'Scheduled Transfer Destination': '搬送予定先',
  'Bill-to': '請求先',
  'JHR Network Service㈱': 'JHRネットワークサービス㈱',
  Dispatch: '出動',
  'Name (Formal)': '氏名',
  'Allow Change Password': 'パスワードの変更権限の付与',
  'Reception Date': '受付日時',
  'Last Modified Date': '最終更新',
  Receiver: '受付者',
  Typing: '入力中',
  'Accident Vehicle Number': '車輌ナンバー',
  'Delete Matter': '案件削除',
  'The given data was invalid.': '指定されたデータは無効でした。',
  'Billing Top': '課金トップ',
  'Pending Purchases': '保留中の購入',
  'Print Bill': '請求書の印刷',
  Record: '記録',
  Remind: '思い出させる',
  'Bill Details': '請求書の詳細',
  'Sorry, no matching records found': '検索条件に該当するデータはありません',
  'Successfully Updated Inquiry': '受付票を登録/修正しました',
  'Special User Successfully Updated': '特殊ユーザーが修正されました',
  'Special User Successfully Created': '特別なユーザーが作成されました',

  'Successfully Import Customer Date': 'データの取り込みが完成されました。',
  'Error Import Customer Date': 'エラーが発生しました。',
  'CSV Validation':
      'CSVファイルを確認してください。\n' +
      ' バリデーションエラーを修正した後に、再度インポートしてください。',

  'row(s) selected': '行が選択されました',
  'The file does not exist': 'ファイルが存在しません',
  'Inquiry Successfully Created': '受付票が作成されました。',
  'Successfully Created Inquiry': '受付票を登録しました。',
  'Inquiry Successfully Updated': '受付票が更新されました。',
  'Successfully Uploaded File': 'アップロードされました',
  'Successfully Deleted File': 'ファイルを削除しました。',
  'Successfully Updated Master Option': 'マスタ項目が更新されました。',
  'Successfully Created Master Option': 'マスタ項目が登録されました。',
  'Successfully Deleted Master Option': 'マスタ項目が削除されました。',
  'Successfully Updated Master Category': 'マスタ種類が更新されました。',
  'Successfully Updated DM Shipping': '発信が失敗しました。',
  'Successfully Submit Content Validation': '登録されました。',
  'Failed Submit Content Validation': '内容の確認が失敗されました。',
  'Successfully Created': '正常に登録されました。',
  'Failed Created': 'エラーが発生さされました。\n' + '更新が失敗されました。',
  'Successfully Updated': '正常に更新されました。',
  'Dealer Successfully Updated': '登録されました。',
  'Dealer Failed Updated': '販社報告の登録が失敗されました。',
  'Failed Updated': 'エラーが発生さされました。\n' + '更新が失敗されました。',
  'Successfully Created Outbound Mobilots': '対象車両区分が更新されました。',
  'Export customer date successfully': 'データの取り込みが完成しました。',
  'Export customer date failed': 'データの取り込みが失敗しました。',
  'Template is updated successfully': 'アップデートされました。',
  'File Must Be in PDF Format': 'ファイルはPDF形式である必要があります',
  'Successfully Created Staff data': 'スタッフ情報を登録しました。',
  'Staff data Successfully Updated': 'スタッフ情報が更新されました。',
  'The selected outgoing sales company does not have the outgoing slip data':
    '選択した発信選択販社は発信票のデータがありません。',
  'Please select an outgoing selection sales company':
    '発信選択販社を選んでください。',
  'Receiver Name': '対応者',
  'Edit Call Recording': '通録編集',
  'Play Call Recording': '通録再生',
  'Add File': 'ファイルを追加',
  'File must be in audio format. (mp3, ogg, aac, wav, wma, etc.)':
    'ファイルはオーディオ形式である必要があります。 （mp3、ogg、aac、wav、wmaなど）',
  'Error accessing the call recording file.':
    '通話録音ファイルへのアクセス中にエラーが発生しました。',
  'Person In Charge': '担当者',
  'CSV successfully downloaded': 'CSVが正常にダウンロードされました',
  'Shift Table': '各種登録・設定',
  Login: 'ログイン',
  'Are you sure you want to switch to another branch? Current changes will not be saved.':
    '支店を切り替えてもよろしいですか？切り替えた場合、変更した箇所は保存されません。',
  pick: '本',
  'Weekday Night': '平日の夜',
  'Business hour': '営業時間',
  'Remarks for Sales Companies': '販売会社へのコメント',
  places: 'ヶ所',
  name: '名',
  table: '台',
  minute: '分',
  'EXPORT PDF': '帳票出力',
  'Work Report Successfully Created': '完了報告が登録されました。',
  'Expressway Search': '高速道路検索',
  Expressway: '高速道路名',
  'Facility Name': '施設名',
  'Facility Name Kana': '施設名かな',
  'Facility Location': '施設所在地',
  'KM Post': 'キロポスト',
  'Contact to': '連絡先',
  'Car Dealer Night Up': '販社夜間上り',
  'Car Dealer Night Down': '販社夜間下り',
  'Car Dealer Daytime Up': '販社日中上り',
  'Car Dealer Daytime Down': '販社日中下り',
  'Request type': '依頼別項目',
  'Vehicle type': '車種',
  'Work category': '作業内容区分',
  Distance: '距離',
  'Detail search': '詳細検索',
  'Tool/Parts category': '工具/部品区分',
  'Road distance': '道のり',
  Availability: '対応可否',
  Available: '対応可',
  'Not available': '対応不可',
  'Done Status': '有',
  'Not Done Status': '無',
  'Reported Status': '報告済',
  'Follow Call Menu': 'フォローコール一覧',
  'OUTBOUND CALL': 'OutBound Call',
  'Created file download': 'ファイルダウンロード',
  'Requested time': '作成日時',
  'Creator name': '作成者名',
  Download: 'ダウンロード',
  Reload: '更新',
  Proccessing: '作成中',
  Failed: '異常終了',
  'Waiting for start': '開始待ち',
  Blank: '空欄',
  'Create Message Successful': '伝言が作成されました。',
  'Create Message Failed': '伝言の作成が失敗されました。',
  'JNS operator': 'JNSオペレーター',
  'Bell system 24': 'ベルシステム２４',
  'Export Outbound Successfully downloaded': 'データ抽出が完成されました。',
  'Export Outbound Failed downloaded': 'データ抽出が失敗されました。',
  // Page Name
  'Hino Connect': {
    // Page Tab
    'Inquiry List': {
      'CONNECT Inquiry List': 'CONNECT 受付一覧',
      'Inquiry Period': '入電日',
      'Reception No.': '受付 No.',
      'Registration No.': '登録番号',
      'Caller Kana': '依頼会社名',
      Caller: '依頼者名',
      'Telephone Number': '電話番号',
      'Car Dealer': '主幹販売会社',
      'Inquiry Category': '問合せ分類',
      'CC Status': 'CCステータス',
      Name: '対応者検索',
      Receiver: '受付者',
      'Person In Charge': '対応者',
      'Escalation To': 'エスカレ先',
      'Search Inquiry': '案件検索',
      'Need Approvement by Office Staff': '承認必要',
      'Not Yet Started by Office Staff': '事務所未対応',
      'In Progress by Office Staff': '事務所対応中',
      'Work Flow': '対応フロー',
      'Inquiry Date': '受付日時',
      'Last Updated': '最終更新',
      'Call Status': '連絡事項',
      'Desired Contact Date': '希望連絡日時',
      'Company Name': '会社名',
      'HITOSS Company Name': 'HITOSS 会社名',
      'Escalation Status': 'エスカレ先ステータス',
      'Escalation Content': 'エスカレ担当者',
      'Name (Escalation To)': 'エスカレ担当者検索',
      'Escalation In Charge': 'エスカレ担当者',
      Inquiries: '件',
      Branch: '支部',
      Classification: '分類',
      Kana: '仮名',
      Series: '指定番号',
      // 'Telephone Number': '連絡先',
      'Caller Name': '依頼者名',
    },
    'Inquiry Form': {
      'User Details Status': 'ユーザー情報ステータス',
      'User ID Name': 'ユーザーID名称',
      'Registered Person': '登録担当者',
      'Registered Email Address': '登録メールアドレス',
      'Integration ID': '統合先ID',
      'Integration ID Name': '統合先ID名称',
      'Registration No.': '登録番号',
      'Car No.': '車体番号',
      User: 'ユーザー',
      'Car Type': '車型',
      'Car Number': '車番',
      'User ID': 'ユーザーID',
      Close: '閉じる',
      Bureau: '支局',
      Category: '分類',
      Katakana: 'かな',
      Correspondents: '対応者区分',
      'Designated Number': '指定番号',
      Destination: 'エスカレ先',
      Search: '検索',
      'Inquiry Form': '問合せフォーム添付',
      'Vehicle Inspection Certificate': '車検証添付',
      Document: '資料添付',
      Attach: '参照',
      Preview: '閲覧',
      'Unsupported File Type':
        '閲覧できるファイルタイプはエクセルまたはPDFになります。',
      'Inquiry Status (Management)': '管理',
      'Reception No.': '受付 No.',
      Receiver: '受付者',
      Inputting: '入力中',
      'Call Status': '連絡事項',
      'Scheduled Call': '発信予定',
      'Wait for Call': '入電予定',
      'Refer to Note': '備考欄参照',
      'Inquiry Date and Time': '入電日時',
      // 'Contact Method': '受信媒体',
      Phone: '電話',
      Email: 'メール',
      'Email Reception Number': 'メール管理番号',
      'Caller Company Name': '依頼会社名',
      'Caller Company Name Kana': '依頼会社名（カナ）',
      'Caller Information': '依頼者情報',
      'Caller Type': '依頼者区分',
      'Caller Name (Katakana)': '依頼者名（カナ）',
      'Caller Name': '依頼者名',
      'Telephone Number': '連絡先',
      'Telephone Number 2': '連絡先②',
      'Car Dealer Person In Charge': '担当者',
      Credit: '与信',
      'Sales Representative': '営業担当者',
      'Query History': '問合せ履歴',
      'User Registration Information (Automatic Reflection)':
        'ユーザー登録情報 (自動反映)',
      'HITOSS Company Name': 'HITOSS 会社名',
      'Main User ID': 'メインユーザーID',
      'User Code': '使用者コード',
      'Registered Person Name': '登録担当者名',
      'Date of Issue of Authentication Code': '認証コード発行日',
      "Registered Person's Email Address": '登録担当者メールアドレス',
      'Main Dealer': '主幹販売会社',
      'Car Dealer Name described in the Agreement Document':
        '承認書記載販売会社',
      'Branch Name': '拠点名',
      'User Status': 'ユーザー情報ステータス',
      'Authentication Code': '認証CD',
      'Vehicle Details': '車両情報（自動反映）',
      'Exhaust Gas Symbol': '排ガス記号',
      Model: '型式',
      'Chassis Number': '車台番号',
      'First Year Registration': '初年度登録',
      'Registration Date': '登録年月日',
      'Delivery Date': '納車日',
      'Onboard Equipment': '車載器の有無',
      'SIM State': 'SIM状態',
      'Written Consent': '承諾書',
      'Vehicle Status': '車両ステータス',
      'Inquiry Details': '問合せ情報',
      'Inquiry Category': '問合せ分類',
      'Major Category': '大分類を選択してください',
      'Middle Category': '中分類を選択してください',
      'Minor Category': '小分類を選択してください',
      'Error Code': 'エラーコード',
      'Error Code List': 'コード一覧',
      Inquiry: '問合せ内容',
      Add: '追加',
      Delete: '削除',
      'Desired Contact Date': '希望連絡日時',
      'Contact Method': '受信媒体',
      'Choose Contact Method': '希望回答方法',
      'Remaining Tasks': '残タスク',
      Complete: '完了',
      'Correspondence Details': '対応内容',
      'Escalation List': 'エスカレ担当一覧',
      'Call Center Status': 'コールセンターステータス',
      'Escalate Status': 'エスカレステータス',
      'Complete Year, Date and Time': '完了日時',
      'Escalation Template Message': 'エスカレ定型',
      History: '経緯',
      'Inquiry History': '対応経緯',
      'Call / Receive Date': '受発信日時',
      'Call and Receive Category': '受発信区分',
      'Receiver Name': '対応者',
      'Person in Charge': '相手方',
      'Inquiry History Category': '内容区分',
      'Inquiry Content': '内容',
      'Attach Call Recording': '通録添付',
      Insert: '挿入',
      Update: '更新',
      'Car Dealer': '販売会社',
      Receive: '受信',
      Call: '発信',
      'Register/Edit': '登録/修正',
      Register: '登録',
      'Request to Office Staff': '事務所への対応依頼',
      Request: '依頼',
      Approvement: '承認必要有無',
      Need: '有',
      'No Need': '無',
      Status: '対応結果',
      'In Progress': '対応中',
      Approve: '承認',
      'Go on to the next inquiry': '次の案件へ',
      'Search History': '履歴検索',
      Save: '保管',
      'Last Updated By:': '最終更新者：',
    },
    'Registration Number': {
      'Main Car Dealer Code': '主幹販社CD',
      'Main Car Dealer Name': '主幹販社名',
      'Branch Name': '担当拠点',
      'User Code': '使用者CD',
      'User (Company) Name': '使用者名',
      'Registaration Date': '初登録日',
      'Onboard Equipment': '車載器',
      'Agreement Document': '承諾書',
      'User Details Status': 'ユーザー情報 ステータス',
      'Main User ID': 'メインユーザー ID',
      'Email Adrress': 'メールアドレス',
      'ID creation date and time': 'ID作成日時',
      'Last login date and time': '最終ログイン日時',
      'Authentication code issue date': '認証コード 発行日',
      'Car dealer': '承諾書 記載販社',
      'Branch and Department Name of Car Dealer': '承諾書 販社拠点/部署',
      'Person in Charge of Agreement': '承諾書 担当者',
      'Emission Symbol': '排ガス記号',
      'SIM Status': 'SIM状態',
      'Search History': '履歴検索',
      'CONNECT Details': 'CONNECT情報',
      'Vehicle History': '車両履歴',
      'Agreement History': '承諾書履歴',
    },
    'Car No': {
      'Registration No.': '登録No,',
      'Emission Symbol': '排ガス記号',
      'Main Car Dealer Code': '主幹販社CD',
      'Main Car Dealer Name': '主幹販社名',
      'Branch Name': '担当拠点',
      'User Code': '使用者CD',
      'User (Company) Name': '使用者名',
      'Registration Date': '初登録日',
      'Delivery Date': '納車日',
      'Onboard Equipment': '車載器',
      'Sim Status': 'SIM状態',
      'CONNECT Details': 'CONNECT情報',
      'Agreement Document': '承諾書',
      'User Details Status': 'ユーザー情報ステータス',
      'Main User ID': 'メインユーザーID',
      'Registered Person': '登録担当者',
      'Email Address': 'メールアドレス',
      'ID Creation Date and Time': 'ID作成日時',
      'Last Login Date and Time': '最終ログイン日時',
      'Authentication Code Issue Date': '認証コード発行日',
      'Car Dealer Describe in the Agreement Document': '承諾書記載販社',
      'Branch and Department Name of Car Dealer Described in the Agreement Document':
        '承諾書販社拠点/部署',
      'Person in Charge of Agreement Document': '承諾書担当者',
      'Vehicle Status': '車両ステータス',
      'Event Name': 'イベント名',
      'Valid Date From': '有効日付FROM',
      'Valid Date To': '有効日付To',
      'Dealer CD': '販社CD',
      'Acceptance Type': '承諾書種別',
      'User Name Describe in the Agreement Document': '承諾書記載使用者名',
    },
    Import: {
      'Email Templates for Escalation': 'エスカレメール定型文',
      'Error Code List': 'エラーコード一覧',
      'Escalation List (PDF)': 'エスカレーション一覧（PDF）',
      'Last Update': '更新日',
      'Import Documents for HINO CONNECT Inquiry Form': 'CONNECT業務資料',
      'Escalation Template Message': 'エスカレ定型文',
      'Escalation Template Message for Call Center Agents': 'OPエスカレ定型',
      'A tool to see the agreement': '承諾書見える化ツール',
      'HINO CONNECT Manual (PDF)': 'HINO CONNECTマニュアル(PDF)',
      'Work Flow': '対応フロー',
      Attach: '参照',
      Close: '閉じる',
      Edit: '修正',
      'No File Selected': 'ファイルが選択されていません',
      Delete: '削除',
    },
  },
  'Hino SOS': {
    'Inquiry List': {
      Name: '対応者検索',
      'Inquiry Period': '入電日時',
      'Person In Charge': '対応者',
      'Company Name Kana': '会社名(カナ)',
      'Driver or Owner Name': 'ドライバー/管理者名',
      'Telephone Number': '電話番号',
      'Reception No.': '受付 No.',
      Star: '★',
      'Tow Truck Company': '出勤スタッフ（外注）',
      'Tow Truck Company (Dispatch Staff)': '出動スタッフ',
      'Service Division': '対応区分',
      'Tow Truck Company (Outsourcing)': '出勤スタッフ（外注）',
      'Need Approval': '承認必要',
      'Office Not Supported': '事務所未対応',
      'Office Correspondence': '事務所対応中',
      'Work Reports': '作業完了',
      Required: '要',
      Entered: '入力済',
      'Road Type': '道路種類',
      'On the Road': '本線',
      'Inside the facility': '施設内',
      Running: '走行中',
      'Car Dealer': '元販売会社',
      Receiver: '受付者',
      'Trouble Category': 'トラブル内容',
      'Correspondence Details': '対応区分',
      'Call Status': '連絡事項',
      'Dispatch Status': '出動ステータス',
      Caller: '発信担当',
      'Client Name': 'お客様名',
      'Registration No.': '登録番号',
      'Car Dealer Branch': '実施販売会社',
      'Request Type': '要請内容',
      'Need Approvement by Office Staff': '承認必要',
      'Not Yet Started by Office Staff': '事務所未対応',
      'In Progress by Office Staff': '事務所対応中',
      Done: '作業完了要',
      Receive: '受信',
      Call: '発信',
      'Call and Receive Category': '受発信区分',
      Create: ' 登録',
      'Device Category': '装置区分',
      'Request Category': 'トラブル内容',
      'Garage Location': '入庫先',
      'Malfunction Category': '故障区分',
      'Client Type': 'お客様属性',
      'Attendance Status': '出勤ステータス',
      'Please Select': '選択してください',
      'Completion Report': '完了報告入力',
      'Billing Name': '請求先名',
      'Awaiting report': '報告待ち',
      Reported: '報告済み',
      'Specific failure': '特定故障',
      'Self-propelled accident': '自走不可事故',
      Wrecker: 'レッカー',
      'Other manufacturers': '他メーカー',
      'Serious case': '重大案件',
      'Tire drop': 'タイヤ脱落',
      Fire: '火災',
      'Personal injury': '人身事故',
      'Loading Count Reception': '期間変更リロード',
      'Other Contact': '問合せその他',
    },
    'Inquiry Form': {
      Bureau: '支局',
      Category: '分類',
      Katakana: 'かな',
      'Designated Number': '指定番号',
      Register: '登録',
      'Fill Data': '選択',
      Close: '閉じる',
      'Branch Name': '担当拠点',
      'Former distributor name when new car': '新車時元販社名',
      'Billing Name': '請求先名',
      'Billing Address': '請求先住所',
      'Client Type': '属性',
      'Client Rank': 'ランク',
      'Applicable time zone name': '適用時間',
      'User (Company) Name': '使用者名',
      'User Name (Katakana)': '使用者カナ',
      'User Address': '使用者住所',
      'Passing vehicle remarks': '通過車両備考',

      'Registration No': '登録No',
      'Emmision Symbol': '排ガス記号',
      'Car Type': '車型',
      'Car Number': '車番',
      Registara: '初登録日',
      'Car Type Name': '車種名称',
      'Car Maker': 'メーカー',
      'E / G model': 'E/G型式',
      'Onboard Equipment': '車載器',

      Call: '発信',
      Receive: '受信',
      Add: '追加',
      Delete: '削除',
      Insert: '挿入',
      'Inquiry History': '対応経緯',
      Background: '経緯',
      'Date and Time': '日時',
      'Call & Receive Category': '受発信区分',
      'Receiver Name': '対応者',
      Details: '内容',
      'Dispatch Status': '出動状況',
      Reaction: 'リアクション',
      Attachment: '通録添付',
      ButtonAttachment: '通録添付',
      Partner: '相手先',
      'Reception No.': '受付 No.',
      'Registration No.': '登録番号検索',
      'Client Name No.': '会社名検索',
      'Car No.': '車型車番検索',
      'Last Updated By:': '最終更新者：',
      'HITOSS Search': 'HITOSS検索',
      'Target Car Dealer (Start)': '開始対象販社',
      'Target Car Dealer (End)': '終了対象販社',
      Search: '検索',
      New: '新規',
      'Inquiry Status (Management)': '管理',
      Receiver: '受付者',
      Inputting: '入力中',
      'Scheduled Call Time': '発信予定時間',
      'Call Status': '連絡事項',
      Caller: '発信担当者',
      'Inquiry Date and Time': '情報取得日時',
      'Inquiry Date and Time Input': '入電日時',
      'Caller Details': '依頼者情報',
      'First Reporter': '第一入報者',
      'Completion of Work': '作業完了',
      'Completion of Arrangement': '手配完了',
      'Car Dealer': '元販売会社',
      'Car Dealer Name': '承認者',
      'Completion of Work (Car Dealer)': '作業完了（元販社）',
      'Car Dealer Branch': '実施販売会社',
      'Car Dealer Branch 2': '実施販売会社2',
      'HITOSS Rank': 'HITOSSランク',
      'HITOSS Vehicle Registration': 'HITOSS車両登録',
      'Maintenance Transaction': '整備取引（部整FLG）',
      'Billing Code': '請求先コード',
      'Billing Name Kana': '請求先名（カナ）',
      'HITOSS Note': 'HITOSS情報備考欄',
      'Client (Company) Name': '会社名（お客様名）',
      Driver: 'ドライバー',
      Admin: '管理者',
      'Service Division': '対応区分',
      'Tow Truck Staff Name': 'レッカースタッフ名',
      'Vehicle Info': '車輌動態情報',
      'In-Vehicle Communication': '車載通信機',
      'Info Acquisition Date and Time': '情報取得日時',
      'Tow Truck Hearing Fields': '販社入庫時聴取項目',
      'Arrangement Type': '入庫種別',
      'Transport Destination': '入庫希望先',
      'After Transport': '入庫後対応',
      Transshipment: '積替',
      Lift: 'リフト',
      'Night in the Car': '車中泊希望',
      'Pick up': '迎車',
      'Vehicle Details': '車両情報',
      'Vehicle No. Search': '車両No.検索',
      'Registration Number': '登録番号',
      'Registration Date': '年式（初年度登録）',
      'Exhaust Gas Symbol': '排ガス記号',
      Model: '型式',
      'Chassis Number': '車台番号',
      'Vehicle Total Weight': '車両総重量',
      'Maximum Load Weight': '最大積載量',
      'Vehicle Shape': '車体の形状',
      'Vehicle Name': '車名（メーカー）',
      'Vehicle Length': '長さ(cm)',
      'Vehicle Width': '幅(cm)',
      'Vehicle Height': '高さ(cm)',
      'Self-Propelled Vehicle': '自走',
      Load: '積荷',
      'Load Details': '積荷情報',
      'Scheduled Service': '運行予定',
      'Distance Travelled': '走行距離',
      'Vehicle Type': '車種',
      'Engine Model': 'エンジン型式',
      'Cart Number': '台車番号',
      'Cart Maker': '台車メーカー',
      'Trouble Details': 'トラブル状況',
      'Request Location': '要請場所',
      'Location Info': '位置情報',
      'Longitude / Latitude': '緯度／経度',
      'Road Type': '道路種類',
      'Request Category': '依頼区分',
      'Device Category': '装置区分',
      'Malfunction Category': '故障区分',
      Troubleshooting: 'トラシュー',
      'Troubleshooting Details': 'トラブル詳細',
      'Failure Code': '故障コード',
      'Work Completion Report 1': '作業完了報告1',
      'Same as Car Dealer Branch': '実施と同じ',
      'Reporter Name': '報告者',
      'Reporter Method': '報告形態',
      'Departure Time': '出発時刻',
      'Arrival Time': '現場到着時刻',
      'Completion Time': '完了時刻',
      'Work Report Distance Travelled': '出張距離',
      'Work Report Distance Transported': '搬送距離',
      'Garage Location': '入庫先',
      'Person In Charge': '対応者',
      'Person In Charge Name': '対応者名',
      'Work Report Transport Destination': '搬送先',
      Treatment: '対応方法（必須）',
      Diagnosis: '診断結果',
      'Treatment Details': '対応内容',
      'Treatment Result (Required)': '対応結果（必須）',
      Note: '特記事項',
      Remarks: '備考',
      'Call Center Memo': 'オペレーターメモ',
      'Call Center Memo (SOS)': 'オペレーターメモ（備考）',
      Type: '種類',
      Transport: '搬送',
      Destination: '搬送先反映',
      'Tow Truck Transport (Empty)': 'レッカー搬送（空車）',
      'Tow Truck Transport (Vehicle)': 'レッカー搬送（実車）',
      'Drive Shaft': 'ドライブシャフト',
      'Propeller Shaft': 'プロペラシャフト',
      'Follow Up Call': 'フォローコール',
      'Follow Up Call Details': 'フォローコール備考',
      'Date Time Follow Call': 'フォロー発信日時',
      'Brake Release': 'ブレーキ解除',
      'Double Tire (Outside)': 'ダブルタイヤ(外側)',
      'Double Tire (Inside)': 'ダブルタイヤ(内側)',
      'Single Tire': 'シングルタイヤ',
      'Air Adjustment': 'エアー調整',
      'Out of Gas': 'ガス欠',
      'Air Bleed': 'エアー抜き',
      Inlock: 'インロック',
      Other: 'その他',
      'Safety Measure': '安全対策',
      'Additional Workers': '追加作業員',
      'Guard Car': '後尾警戒車',
      'Waiting Time': '待機時間',
      'Treatment Result': '対応結果',
      'Work Details': '作業詳細',
      'Approval Required': '承認必要有無',
      'Response Results': '対応結果',
      Status: '状況',
      'Date and time': '日時',
      'Schedule Payment Date': '入金予定日',
      'Corresponding Person': '対応者',
      Claim: 'クレーム',
      Export: '出力',
      'Year and Month': '対象年月',
      'Start Date': '開始年月',
      'End Date': '終了年月',
      'Target year': '対象年',
      'Target period': '対象 上期下期',
      'SOS Export Data': 'SOS対応データ',
      'Yearly Data Export for Call Centers':
        '日野SOS中央コールセンター全案件データ 年間 or 半年',
      'Monthly Data Export for Car Dealers': '販売会社 月間対応データ',
      'Monthly Data Export for CC':
        '日野SOS中央コールセンター全案件データ 月間',
      'Data Type': 'データ種類',
      'Car Dealer Search': '販社情報 閲覧',
      'Car Dealer Company Name': '販売会社名',
      DispatchStatusModal: '出動状況',
      'EXPORT PDF': '帳票出力',
      'Reception Date and Time': '受付日時',
      'Created file download': 'ファイルダウンロード',
      'Requested time': '作成日時',
      Download: 'ダウンロード',
      Reload: '更新',
      Complete: '完了',
      Proccessing: '作成中',
      Failed: '異常終了',
    },
    'Company Name': {
      'Main Car Dealer Code': '主幹販社名',
      'Branch Name': '担当拠点',
      'Client Name': 'お客様名',
      'Client name (Katakana)': 'お客様名カナ',
      Address: '住所',
      'Client Type': 'お客様属性',
      'Client Rank': 'お客様ランク',
      'Maintenance Transaction': '整備取引',
      'Applicable time zone name': '適用時間帯名',
      'Passing vehicle remarks': '通過車両備考',
    },
    'Shift Table': {
      'Search Car Dealer': '各種登録・設定',
      'Car Dealer': '販売会社',
      'Free Input': 'フリーワード',
      Search: '検索',
      'Change History': '変更履歴',
      'Shift Table': '当番表',
      'Basic Info': '基本情報',
      Year: '年',
      Month: '月',
      'Import CSV': 'CSV取込',
      'Night Shift': '夜間対応',
      'Day Shift': '日中対応',
      Mechanic: 'メカニック',
      Adviser: 'アドバイザー',
      Other: 'その他（例：外注）',
      'Dispatch Repair': '手動修理',
      'User Inquiry': '承認',
      Approval: 'ユーザー照会',
      'Telephone Consultation': '入庫管理',
      'Warehousing Repair': '電話相談',
      Warehousing: '入庫預かり',
      'Date and Day': '日付',
      Name: '氏名/拠点',
      'TEL Number': '連絡先',
      'Forklift Rental': 'フォークリフト貸出',
      'Night in the Car': '車中泊可否',
      'Emergency Contact': '緊急連絡先',
      Priority: '優先順位',
      'Emergency Car Dealer': '販売会社名',
      'Emergency Car Dealer Branch': '拠点名',
      Position: '役職',
      'Emergency Contact 1': '緊急連絡先①',
      'Emergency Contact 2': '緊急連絡先②',
      Note: '備考',
      'Subcontractor Kust': '外注一覧',
      'Sub Contractor Branch': '拠点',
      'Sub Contractor': '会社名',
      Address: '住所',
      'TEL 1': '出動要請電話番号①',
      'TEL 2': '出動要請電話番号②',
      'Dispatchable Area': '出動可能エリア',
      'Subcontractor Car Dealer Branch': '担当販社拠点',
      'Onsite Repair Work Type': '現場修理作業種別',
      Edit: '修正',
    },
    'Modal Button J': {
      'Staff No': 'スタッフNo',
      'Staff Name': 'スタッフ名',
      'Staff Contact': 'スタッフ連絡先',
      Branch: '支部',
      'Person In Charge': '担当者',
      'Wrecker Classification': 'レッカー区分',
      'Impression Of Charge': '担当印象',
      Dispatch: '出動',
      'Cancellation fee information': 'キャンセル料案内',
      Remarks: '備考',
      Cancel: 'キャンセル',
      'Reason For Cancellation': 'キャンセル理由',
      Delete: '削除',
      Save: '修正',
      Close: '閉じる',
      'Dispatch Status': '出動状況',
      'Required Status': '出動を選択してください。',
      'Required Abolition Classification': 'レッカー区分を選択してください。',
      'Required Status And Abolition Classification': 'レッカー区分 · 出動を選択してください。',
    },
    'Modal Button A': {
      DispatchStatusModal: '出動状況',
      'Staff No': '外注スタッフNo',
      'Staff Name': '外注スタッフ名',
      'Staff Info': '外注スタッフ連絡先',
      'Status Fill Staff Name': '実施販売会社２',
      'Person In Charge': '担当者',
      Dispatch: '出動',
      Memo: '備考',
      Cancel: 'キャンセル',
      'Reason For Cancellation': 'キャンセル理由',
      'Required Status': '出動を選択してください。',
    },
    'Modal Completion Report': {
      'Car Dealer': '元販売会社',
      'Registration number': '登録番号',
      'Arrival Time': '現場到着時刻',
      'Completion Time': '完了時刻',
      'Treatment Details Note': '作業内容を入力してください',
      Note: '申し込り事項を入力してください',
      'Treatment Result': '対応結果（必須）',
      'Transport Distance': '搬送距離',
      'Work Completion Report': '作業完了報告',
      'Work Completion Report 1': '作業完了報告 1',
      'Work Completion Report 2': '作業完了報告 2',
      'Work Completion Report 3': '作業完了報告 3',
      'Dispatch Status': '完了報告入力',
      'Treatment Id Required': '対応方法（必須）を入力してください',
      'Treatment Result Required': '対応結果（必須）を入力してください',
      'Treatment Required': '対応方法（必須）、対応結果（必須）を入力してください',
    },
  },
  Setting: {
    HitossUrl: {
      'Update HitossUrl Successful': 'サーバIPアドレスが更新されました。',
      'Update HitossUrl Fail': 'サーバIPアドレスが設定できません。',
    },
  },
  'Message System': {
    'Have a Errors,Please try again later': 'エラーが発生されました。',
  },
  'JUST DO IT': {
    'Follow Call': {
      'Follow Call': 'フォローコール',
      'Business division': '業務区分',
      'Company Name': '会社名',
      All: '全体',
      'Not sent': '未発信',
      Redial: '再発信',
      'Not entered': '未入力',
      'Unfinished report': '作完未報告',
      Search: '検索',
      'Power Date': '入電日時',
      'Reception No.': '受付 No.',
      Destination: '入庫先',
      'Correspondence Contents': '対応内容',
      'Follow Call Remarks': 'フォローコール備考',
      'Request Base': '依頼拠点',
      'Phone Number': '電話番号',
      Division: '区分',
      Status: 'ステータス',
      'Dispatch Staff': '出動スタッフ',
    },
    'Call List': {
      'Outgoing Schedule': '発信予定',
      'Contact Search': '担当者検索',
      Unarranged: '未手配',
      'Main Line Not  Arranged': '本線未手配',
      'Date Time Incoming Call': '入電日時',
      'Reception No': '受付No.',
      'Request Items': '依頼事項',
      Receptionist: '受付者',
      'Scheduled Transmission Date And Time': '発信予定日時',
      'Business Division': '業務区分',
      'Company Name': '会社名',
      'Registration Number': '登録番号',
      'Request Classification': '依頼区分',
      'Failure Classification': '故障区分',
      'Request Place': '要請場所',
      Caller: '発信担当者',
      History: '経緯数',
      All: '全体',
      Search: '検索',
      Inquiries: '件',
    },
    'Apply Call': {
      'Arrange Classification': '手配区分',
      'Outgoing Schedule': '発信予定',
      'Contact Search': '担当者検索',
      Unarranged: '未手配',
      'Main Line Not  Arranged': '本線未手配',
      'Date Time Incoming Call': '入電日時',
      'Reception No': '受付No.',
      'Request Items': '依頼事項',
      Receptionist: '受付者',
      'Scheduled Transmission Date And Time': '発信予定日時',
      'Business Division': '業務区分',
      'Company Name': '会社名',
      'Registration Number': '登録番号',
      'Request Classification': '依頼区分',
      'Failure Classification': '故障区分',
      'Request Place': '要請場所',
      Caller: '発信担当者',
      History: '経緯数',
      Inquiries: '件',
    },
  },
  'Outbound Call': {
    Outbound: {
      Name: '一覧',
      'Seller Status': '販売会社ステータス',
      'Waiting For Preparation Period': '準備期間待ち',
      'DM Delivery': '要 DM 発送',
      'DM Shipping': 'DM 発送',
      'Good Receipt Promotion Call': '入庫促進コール',
      'Target Number': '対象件数',
      Sent: '発送済み',
      'Call Completed': '発信完了',
      'Number Of Collaborations': '連携件数',
      'Calling Required': '要発信',
      Unconfirmed: '未確認',
      'To Report': '要入庫報告',
      'Job Report': '要作業報告',
      'Preparation For After-Sales Dispatch': 'アフター発信準備',
      'After Sales Required': '要アフター発信',
      Done: '完了',
      'Remaining Days Warning': '残日数警告',
      'Within 10 days': '10 日以内',
      'Within 15 days': '15 日以内',
      'Within 20 days': '20 日以内',
      Overall: '全体',
      'Call At Specified Time': '指定時刻発信',
      'Sales Master': '販売会社マスタ',
      'Sale Company': '販売会社',
      'Overall Option': '全体',
      Submit: '登録',
      Cancel: '戻る',
      'Vehicle Inspection': '車検',
      'Monthly Inspection': 'ヶ月点検',
      'Severe Condition Inspection': 'シビアコンディション点検',
      'Sales Company Management': '販社管理',
      Contract: '契約',
      'Days Setting': '日数設定',
      'Maximum Calls': 'コール数上限',
      'After Call': 'アフターコール',
      'CC Status': 'CCステータス',
      'Sales Company Status': '販社ステータス',
      'Storage Date Left': '入庫残日数',
      'Last Call Date': '最終発信日時',
      'Vehicle Classification': '車両区分',
      'Inspection Category': '点検区分',
      'Inspection Date': '点検日',
      'Reception Management Number': '受付管理番号',
      'Sales Company': '販売会社',
      Base: '拠点',
      'Client Name': 'お客様名',
      'Registration Number': '登録番号',
      'Call Status': '発信状況',
      'Storage Intention': '入庫意向',
      'Goods Receipt Date': '入庫結果',
      'Dealer Link': '販社連携',
      Choice: '選択',
      'Rows per Page': '表示件数',
    },
    'DM Shipping': {
      Name: 'DM 発送',
      'Select All': '全て選択',
      Printing: '印刷',
      Shipping: '発送',
      'Sales Company': '販売会社',
      'Sales Company Name': '販売会社名',
      'CC Status': 'CC ステータス',
      'Inspection Category': '点検区分',
      'Inspection Date': '点検日',
      'Reception Management Number': '受付管理番号',
      Base: '拠点',
      'Vehicle Classification': '車両区分',
      'Customer Name': 'お客様名',
      'Registration Number': '登録番号',
      'Postal Code': '郵便番号',
      Address: '住所',
    },
    Report: {
      Name: 'レポート',
      'Report Selection': 'レポート選択',
      'Inspection Classification': '点検区分',
      'Vehicle Classification': '車両区分',
      'Target Year': '対象年月',
      Year: '年',
      Month: '月',
      'Sales Company': '販売会社',
      'The Entire': '全体',
      Hub: '拠点',
      'Service Satisfaction': 'サービス満足度',
      'Request For Improvement': '改善要望',
      Return: '戻る',
      'Company Name': '会社名',
      'Main Sales Company': '主幹販社',
      'Reason For Other Companies': '他社理由',
      'Other Factory Name': '他社工場名',
      'Warehousing Promotion Transmission Result': '入庫促進発信結果',
      'After-call Count': 'アフターコール発信結果',
      'Operator Dispatch Total': 'オペレータ発信集計',
      'Sales Company Report Count': '販社報告件数',
      'Monthly Breakdown': '月度 内訳',
      'Other Company': '他社',
      'Registration number': '登録番号',
      'Vehicle status': '車両状況',
      'Bug status': '不具合状況',
      Details: '詳細',
      'Reason For Not Receiving Next Time': '他整備工場に入庫する',
      'Reason For Dissatisfaction': '不満理由',
      'Company Dealer': '販売会社',
      Branch: '拠点',
      'Desire Warehouse': '入庫希望',
      'Is Considering': '検討中',
      'Undecided/PR': '未定 /PR 済み',
      'Not Warehouse': '入庫しない / 他社入庫',
      'Not Vehicle': '車両無し / 転売',
      'Not Received Call': '電話不通',
      Total: '総計',
      'No Problem': '不具合無し',
      'There Is A Problem': '不具合有り',
      'Tokyo Hino': '東京日野',
      'Wakayama Hino': '和歌山日野',
      'Okayama Hino': '岡山日野',
      'Kyushu Hino': '九州日野',
      'Customer status': '顧客状況',
      'Warehousing destination': '入庫先',
      'Edogawa Branch': '江戸川支店',
      'Rokugo Branch': '六郷支店',
      'Adachi Branch': '足立支店',
      'Hachioji Branch': '八王子支店',
      'Mizuho Branch': '瑞穂支店',
      'Itabashi Branch': '板橋支店',
      'Omiya Branch': '大宮支店',
      'Asaka Branch': '朝霞支店',
      'Miyoshi Branch': '三芳支店',
      'Matsubushi Branch': '松伏支店',
      'Wakayama Branch': '和歌山支店',
      'Tanabe Branch': '田辺支店',
      'Shinjuku Sales Office': '新宿営業所',
      'Okayama West Branch': '岡山西支店',
      'Okayama East Branch': '岡山東支店',
      'Kurashiki Branch': '倉敷支店',
      'Tsuyama Branch': '津山支店',
      'Fukuoka Branch': '福岡支店',
      'Koga Branch': '古賀支店',
      'Itoshima Branch': '糸島支店',
      'Kitakyushu Branch': '北九州支店',
      'Mizumaki TSC': '水巻 TSC',
      'Chikuho Branch': '筑豊支店',
      'Kurume Branch': '久留米支店',
      'Saga Branch': '佐賀支店',
      'Nagasaki Branch': '長崎支店',
      'Shimabara TSC': '島原 TSC',
      'To Store': '入庫する',
      'Do Not Store': '入庫しない',
      Unknown: '不明',
      'Very Satisfied': '非常に満足',
      'No Communication': '不通',
      Dissatisfaction: '不満 ',
      'Receipt Promotion Call Slip': '入庫促進発信票',
      'Outgoing Result': '発信結果',
      'Improvement Request': '改善要望',
      Satisfaction: '満足',
      'Hope For Improvement': '改善希望',
      Detail: '詳細',
      'Value-added Service': '付加価値\n' + 'サービス',
      'Reducing Working Days': '作業日数の短縮',
      'Ease Of Warehousing': '入庫しやすさ',
      'Next Warehousing': '次回入庫',
      'Operator name': 'オペレータ名',
      'Good Receipt Promotion Call': '入庫促進コール',
      'After Call': 'アフターコール',
      Maintenance: 'メンテリース',
      'Grand total': '総計',
      Mobilot: 'MOBILOTS',
      '1st Outgoing Connection': '第 1 発信接続',
      '2st Outgoing Connection': '第 2 発信接続',
      '3st Outgoing Connection': '第 3 発信接続',
      'No.': '第',
      'Outgoing Connection': '発信接続',
      'Unable To Connect': '接続できず',
      'Number Of Unreported Cases': '未報告件数',
      'Work Unreported': '作業未報告',
      'Unreported Warehousing': '入庫未報告',
      Unconfirmed: '未確認',
      'Number Of Reports': '報告件数',
      'Sales Company Warehousing': '販社入庫',
      'Other Companies Warehousing': '他社入庫',
      'Other Company Name': '他社名',
      'Sales Representative': '営業担当者',
      Search: '検索',
    },
    'Various basic information': {
      Name: '各種基本情報',
      'Master Setting': 'マスタ設定',
      'Master Type': 'マスタ種類',
      'Call Origination Status': '発信記録　発信状況',
      'Target Vehicle Category': '対象車両区分',
      'Display Order': '表示順',
      'Master CD': 'マスターCD',
      'Master Item': 'マスター項目',
      Estimate: '作業工数（日数）',
      'Vehicle Inspection': '車検',
      'Delete Confirm': '削除してよろしいですか？',
      'Cant Delete': 'このマスター情報が利用されていますので、削除できません。',
      Close: '閉じる。',
      Yes: 'はい',
      No: 'いいえ',
      Inspection: '点検',
      Plan: 'プラン',
      'A Day Ago': '日前',
      'Days After': '日後',
      Times: '回',
      Choice: '選択',
    },
    'Customer date': {
      Name: 'Customer data',
      Capture: '取り込み',
      Extraction: '抽出',
      'File Name': 'ファイル名',
      Reference: '参照',
      'Sales company': '販売会社',
    },
    'Promote warehouse call list': {
      Name: '入庫促進コール一覧',
    },
    'Detail promote warehouse call list': {
      'Receipt Promotion Project Slip': '入庫促進案件票',
      Confirm: '内容確認',
      Return: '戻 る',
      'About Us': '会社情報',
      'Message Board': '伝言板',
      Notice: 'お知らせ',
      'Company Name': '会社名',
      'Company Name (Kana)': '会社名（カナ）',
      'Customer Code': '顧客コード',
      'TEL-1': 'TEL-1',
      'TEL-2': 'TEL-2',
      'Street Address': '住所',
      'Vehicle Person In Charge': '車両担当者',
      Mr: '様',
      'Sales Company Information': '販社情報',
      'Maintenance Chief Sales Company': '整備主幹販売会社',
      'Sales Representative': '営業担当者',
      'Maintenance Staff': '整備担当者',
      'Vehicle Information': '車両情報',
      Maker: 'メーカー',
      'Vehicle Type': '車種',
      'Body Shape': '車体の形状',
      'Exhaust Gas Symbol': '排ガス記号',
      Model: '型式',
      'Chassis Number': '車台番号',
      'Vehicle Inspection Expiration Date': '車検満了日',
      'Inspection Classification': '点検区分',
      'Vehicle Inspection History': '車検履歴',
      'Vehicle Inspection Classification': '車検区分',
      'First Year Registration': '初年度登録',
      'Receipt Promotion Call Slip': '入庫促進発信票',
      'Reception Management Number': '受付管理番号',
      'Record Call': '発信記録',
      Delete: '削除',
      'Call Date And Time': '発信日時',
      'Corresponding Person': '対応者',
      'Date Time Next Call': '次回発信日時',
      Sender: '発信者',
      'Call Status': '発信状況',
      'Situation details': '状況詳細',
      'Voice 1': '音声１',
      'Voice 2': '音声2',
      'Outgoing result': '発信結果',
      'Intention To Store': '入庫意向',
      'Warehousing Destination': '入庫先',
      'Other Company Name': '他社名',
      'Reasons For Other Companies': '他社理由',
      'Request For Improvement': '改善要望',
      'Vehicle Status': '車両状況',
      'Customer Status': '顧客状況',
      Service: 'サービス',
      'Cooperation Matters': '連携事項',
      'Contact Request': '連絡要請',
      'Desired Date Of Warehousing': '入庫希望日',
      'Hope To Pick Up': '引取希望',
      'Hope To Deliver': '納車希望',
      'Contact Information': '連絡先',
      'Desired Date Of Completion': '完成希望日',
      'Pick Up Location': '引取場所',
      'Delivery Place': '納車場所',
      Mileage: '走行距離',
      'Warehousing Sales Company': '入庫希望販社',
      'Details Of Cooperation Items': '連携事項詳細',
      'Escalation History': 'エスカレ経緯',
      'Escalate Destination': 'エスカレ先',
      'Scheduled For Maintenance': '整備予定',
      'Work Man-Hours (Days)': '作業工数（日数） ',
      Request: '要望',
      'Sales Company Report': '販社報告',
      'Receipt Result Report': '入庫結果報告',
      'Goods Receipt Result': '入庫結果',
      'Correspondence Date': '対応日',
      'Sales Company Status': '販社ステータス',
      'Goods Receipt Date': '入庫日',
      'Scheduled Delivery Date': '出庫予定日',
      'Work Report': '作業報告',
      'Delivery Date': '出庫日',
      'Work Content': '作業内容',
      Register: '登録',
      'Vehicle classification': '車両区分',
      'Contract Type': '契約形態',
      'Management Number': '管理番号',
    },
    'After call list': {
      Name: 'アフターコール一覧',
    },
    'Maintenance lease call list': {
      Name: 'メンテリースコール一覧',
    },
    'Outbound CountData': {
      'To be sent today': '●本日発信予定',
      'Warehousing promotion': '入庫促進',
      'After sales': 'アフター',
      Achievements: '●実績',
      Miyamura: '宮村',
      Total: '合計',
      'The rest': '残',
      Fujikawa: '藤川',
    },
    'Outbound Search': {
      'Inspection category': '点検区分',
      'Vehicle classification': '車両区分',
      'Company name (Kana)': '会社名（カナ）',
      'Customer code': '顧客コード',
      'Phone number': '電話番号',
      'Vehicle personnel': '車両担当者',
      'Reception management number': '受付管理番号',
      'Target period': '対象期間',
      'Remaining days': '残日数',
      'Sales company': '販売会社',
      'Sales representative': '営業担当者',
      'Maintenance personnel': '整備担当者',
      'Registration number': '登録番号',
      'Car type/car number': '車型／車番',
      'Outgoing selective sales company': '発信選択販社',
      'Inspection dispatch': '点検発信',
      'After-sales dispatch': 'アフター発信',
      'Blank Company': '販売会社名',
      'Blank Company Branch': '拠点名',
    },
    'Outbound CallCenterStatus': {
      'Call center status': 'コールセンターステータス',
      'Seller status': '販売会社ステータス',
    },
    'Outbound AfterCallCenterStatus': {
      'Call center status': 'コールセンターステータス',
      'Seller status': '販売会社ステータス',
    },
    'Outbound Vote': {
      'After Call Center': 'アフターコール発信票',
      'After-call Case Slip': 'アフターコール案件票',
      'Company name': '会社名',
      'Company name (Kana)': '会社名（カナ）',
      'Customer code': '顧客コード',
      'Center name': 'センター名',
      'TEL-1': 'TEL-1',
      'TEL-2': 'TEL-2',
      'Street address': '住所',
      'Vehicle personnel': '車両担当者',
      Mr: '様',
      Position: '役職',
      'Last call record': '前回発信実績',
      'Latest receipt date': '最新入庫日',
      'Storage contents': '入庫内容',
      'Main maintenance company': '整備主幹販売会社',
      'Sales representative': '営業担当者',
      'Maintenance personnel': '整備担当者',
      Manuscript: '投 稿',
      'Tokyo Hino dispatch': '東京日野発信',
      'Change history': '変更履歴',
      'Outgoing vote': '入庫促進発信票',
      Return: '戻 る',
      Confirm: '内容確認',
      'Next item': '次の案件',
      'Company information': '会社情報',
      'Notice': 'お知らせ',
      'Distributor information': '販社情報',
      'Message board': '伝言板',
    },
    Messages: {
      'Input Message': '伝言板入力',
      'Please enter.': '入力してください。',
      'Message board confirmation': '伝言板確認',
      'Mr.': 'さん',
    },
    'Outgoing Calls': {
      Name: '発信票',
      'Vehicle Information': '車両情報',
      Register: '登録',
      Manufacturer: 'メーカー',
      Model: '車種',
      'Body Shape': '車体の形状',
      'Exhaust Gas Symbol': '排ガス記号',
      Type: '型式',
      'Chassis Number': '車台番号',
      Classification: '整備担当者',
      'Vehicle classification': '車両区分',
      'Contract Type': '契約形態',
      'Management Number': '管理番号',
      'Control Number': '管理番号',
      'Inspection Expiration': '車検満了日',
      'First Year Registration': '初年度登録',
      'Shipping Date': 'DM 発送日',
      'Inspection Category': '点検区分',
      'Next Inspection Day': '次回点検日',
      'Inspection History': '車検履歴',
      'Vehicle Inspection Classification': '車検区分',
      'View Maintenance History': '整備履歴を見る⇀',
      'Last Call Record': '前回発信記録',
      'Reception Management Number': '受付管理番号',
      'After Call Center': 'アフターコール発信票',
      'Call Date and Time': '発信日時',
      'Corresponding Person': '対応者',
      'Receipt Promotion Call Slip': '入庫促進発信票',
      Mr: '様',
      Sender: '発信者',
      'Call Status': '発信状況',
      'Situation Details': '状況詳細',
      'Record Before Last Call': '前々回発信記録',
      'Owned Vehicle Information': '保有車両情報',
      'CC Status': 'CC ステータス',
      'Last Call Date': '前回発信日',
      'Vehicle Classification': '車両区分',
      'Number Of Days Left For Inspection': '点検残日数',
      'Car Inspection Expiration Date': '車検満了日',
      'Registration Number': '登録番号',
      Maker: 'メーカー',
      'Last Receipt Date': '最終入庫日',
      'Last Call': '前回発信',
      'Last Time': '前回内容',
      'Sales Company Status': '販社ステータス',
      'Record Call': '発信記録',
      'Record After Call': 'アフターコール　発信記録',
      Delete: '削除',
      'Outgoing result': '発信結果',
      'Calling Time': '発信日時',
      Caller: '発信者',
      'Date Time Next Call': '次回発信日時',
      Details: '状況詳細',
      Add: '追加',
      Close: '閉じる',
      'Voice 1': '音声１',
      'Voice 2': '音声2',
      'Call Result': '発信結果',
      'Storage Intention': '入庫意向',
      'Storage Destination': '入庫先',
      'Company Name': '他社名',
      'Reason for other companies': '他社理由',
      Improvement: '改善要望',
      'Vehicle status': '車両状況',
      'Customer situation': '顧客状況',
      Service: 'サービス',
      'Sales Company Report': '販社報告',
      'Goods Receipt Result Report': '入庫結果報告',
      'Response Date': '対応日',
      'Goods Receipt Result': '入庫結果',
      Destination: '作業内容',
      'Other Company Name': '他社名',
      'Goods Receipt Date': '入庫日',
      'Scheduled Departure Date': '出庫予定日',
      'Work Report': '作業報告',
      Mileage: '走行距離',
      'Detail Cooperation': '連携事項詳細',
      'Departure Date': '出庫日',
      'Work Content': '作業内容',
      'Bug handling': '不具合対応',
      'After-call Transmission Record': 'アフターコール　発信記録①',
      'No Abnormality': '異常なし',
      'After driving, there was no particular abnormality.':
        '走行後、特に異常なし。',
      'Very satisfied': '非常に満足',
      'Next Warehousing': '次回入庫',
      'Enter the sales company': '販売会社に入庫する',
      Voice: '音声',
      'Cooperation item': '連携事項',
      'Contact Request': '連絡要請',
      Yes: '有り',
      'Warehousing dates': '入庫希望日',
      'Wish to collect': '引取希望',
      'Delivery Hope': '納車希望',
      'Escalation History': 'エスカレ経緯',
      'Escalate Destination': 'エスカレ先',
      'Telephone Number': '連絡先',
      'Desired completion date': '完成希望日',
      'Collection place': '引取場所',
      'Delivery Location': '納車場所',
      'Desired Sales Company': '入庫希望販社',
      'Scheduled To Maintain': '整備予定',
      'Working Time (days)': '作業工数(日数)',
      Request: '要望',
      Response: '対応依頼書',
      NotFoundOutBoundCall: '入庫促進発信票が見つかりません。',
      NotFoundAfterOutBoundCall: 'アフターコール発信票が見つかりません。',
    },
    'Basic Information': {
      'Register/update Master Company successful':
        '販売会社マスタが登録されました。',
      'Register/update Master Company failed': 'エラーが発生されました。',
    },
    'Outbound External': {
      Choice: '選択',
      'CC Status': 'CCステータス',
      'Last call date and time': '最終発信日時',
      'Sales company cooperation': '販社連携',
      'Inspection classification': '点検区分',
      'Inspection Date': '点検日',
      Base: '拠点',
      'Customer name': 'お客様名',
      'Registration number': '登録番号',
      'Outgoing status': '発信状況',
      'Intention to store': '入庫意向',
      'Situation details': '状況詳細',
      'Goods receipt result': '入庫結果',
      'Reception Management Number': '受付管理番号',
      'Sale company': '販売会社',
      'Bug details': '不具合詳細',
      'Bug handing': '不具合対応',
      'EXCEL data extraction': 'EXCEL データ抽出',
      'Dealer link': '販社連携',
    },
    'Dealer/Maker Search': {
      Company: '販売会社',
      'Blank Company': '販売会社名',
      'Company Branch': '拠点',
      'Blank Company Branch': '拠点名',
      'Target Date': '対象年月',
      Year: '年',
      Month: '月',
      'Registration number': '登録番号',
      'Sales representative': '営業担当者',
      Cooperation: '連携',
      'Company name (Kana)': '会社名（カナ）',
      'Car type/car number': '車型／車番',
      'Intention to Storage': '入庫意向',
      'Goods Receipt Result': '入庫結果',
      'Outgoing status': '発信状況',
    },
    'Outbound External Promote Warehouse Table': {
      'EXCEL data extraction': 'EXCEL データ抽出',
      Choice: '選択',
      'CC Status': 'CC ステータス',
      'Last call date and time': '最終発信日時',
      'Sales company cooperation': '販社連携',
      'Inspection classification': '点検区分',
      'Inspection Date': '点検日',
      Base: '拠点',
      'Customer name': 'お客様名',
      'Registration number': '登録番号',
      'Outgoing status': '発信状況',
      'Intention Store': '入庫意向',
      'Situation Details': '状況詳細',
      'Goods Receipt Result': '入庫結果',
      'Sales Company': '販売会社',
    },
    NotificationBox: {
      Notification: 'お知らせ',
      BeforeCodeNotification: ' 未確認の連携案件があります。受付管理番号 ',
      AfterCodeNotification: ' をクリックしてご確認ください',
    },
  },
  CommonLinks: {
    Email: 'メール',
    Excel: '見える化ツール',
    Inquiry: 'マニュアル',
  },
};

/**
 * Helper function to translate
 * words to Japanese
 *
 * @param {string} word
 */
export function translate(word, module = { page: '', tab: '' }) {
  const language = process.env.REACT_APP_LANGUAGE;

  const { page, tab } = module;
  if (page) {
    if (tab) {
      return word in translation[page][tab] && language === 'jp'
        ? translation[page][tab][word]
        : word;
    }
    return word in translation[page] && language === 'jp'
      ? translation[page][word]
      : word;
  }

  return word in translation && language === 'jp' ? translation[word] : word;
}

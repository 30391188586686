import React from 'react'

export const roles = {
  administrator: '管理者',
  callCenterAgent: 'CC管理者',
  carDealer: '販売会社',
  carMaker: 'カーメーカー',
  towTruckCompany: 'Tow Truck Company',
};

export const messageUser = {
  Operator: 'OP',
  Dealer: '販社',
  Maker: 'メーカー',
}

export const callType = {
  before: 'before',
  after: 'after',
}

export const client_types = {
  S客: 'Ｓ',
  N客: '',
  B客: 'Ｂ',
  G客: 'Ｇ',
  不明: '不明',
};

export const hinoSosReportMethod = {
  user: '電話',
  dealer: 'システム',
};

export const record_call = {
  0: '①',
  1: '②',
  2: '③',
  3: '④',
  4: '⑤',
  5: '⑥',
  6: '⑦',
  7: '⑧',
  8: '⑨',
  9: '⑩',
  10: '⑪',
  11: '⑫',
  12: '⑬',
  13: '⑭',
  14: '⑮',
  15: '⑯',
  16: '⑰',
  17: '⑱',
  18: '⑲',
  19: '⑳',
  20: '㉑',
  21: '㉒',
  22: '㉓',
  23: '㉔',
  24: '㉕',
  25: '㉖',
  26: '㉗',
  27: '㉘',
  28: '㉙',
  29: '㉚',
  30: '㉛',
  31: '㉜',
  32: '㉝',
  33: '㉞',
  34: '㉟',
  35: '㊱',
  36: '㊲',
  37: '㊳',
  38: '㊴',
  39: '㊵',
  40: '㊶',
  41: '㊷',
  42: '㊸',
  43: '㊹',
  44: '㊺',
  45: '㊻',
  46: '㊼',
  47: '㊽',
  48: '㊾',
  49: '㊿',
};

export const regex = {
  numeric: /^[０-９0-9\b.]+$/,
  numericAndSolidus: /^[０-９0-9\/\b.]+$/,
  japanNumeric: /[０-９]/g,
  inputNumeric: /^[0-9.]+$/,
  onlyInputNumeric: /^[0-9]+$/,
  numericWithForwardSlash: /^[0-9\b.\/]+$/, //eslint-disable-line
  alpha_num: /^[0-9一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤]+$/u,
  integer: /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g,
  textJapan1: /[\u30a0-\u30ff]/, //カタカナ
  textJapan2: /[\u3040-\u309f]/, //ひらがな
  textJapan3: /[\u4e00-\u9faf]/, //漢字
  telephone_number: /^[0-9\-]+$/, //電話番号
  registration_number_bureau: /^[一-龠ぁ-ゔヶァ-ヴ０-９々〆〤]+$/u, //支局
  registration_number_bureau_replace: /[^一-龠ぁ-ゔヶァ-ヴ０-９々〆〤]/g,
  registration_number_category: /^[0-9０－９]+$/, //分類
  registration_number_kana: /[あ-ん]/, //かな
  registration_number_kana_and_hiragana: /^[ぁ-んァ-ンｱ-ﾝ]+$/,
  registration_number_kana_replace: /[^あ-ん]/gi, //かな
  registration_number_designated_number: /^[0-9０－９]+$/, //指定番号
  katakana: /^[ァ-ヴ゛゜　ーｦ-ﾟa-zA-Z0-9 ()-/:-@\[-~]+$/, //カタカナ
  billing_name_kana_replace: /[^ァ-ヴ゛゜　ーｦ-ﾟa-zA-Z0-9 ()-/:-@\[-~]/gi,
  client_company_katakana: /[^ァ-ヴ゛゜　ーｦ-ﾟa-zA-Z0-9 ()-/:-@\[-~]/gi,
  halfKatakana: /^[ｦ-ﾟa-zA-Z0-9 -/:-@\[-~]+$/, //半角カタカナ
  hiraganaAndKatakana: /^[ぁ-んァ-ンa-zA-Z]+$/,
  halfAlphaNumSymbol: /^[a-zA-Z0-9 -/:-@\[-~]+$/, //半角英数記号
  halfAlphaNum: /^[a-zA-Z0-9]+$/, //半角英数
  halfNumSymbol: /^[0-9 -/:-@\[-~]+$/, //半角数記号
  numberAndLetter: /^[0-9a-zA-Z]*$/,
  maxLength255: /^.{1,255}$/,
  tow_truck_staff_contact_number: /^[0-9０-９-/、]+$/,
  numericFullAndHalf: /^[０-９0-9]+$/,
  numericHalf: /^[0-9]+$/,
  japanese_replace: /[^一-龠ぁ-ゔヶァ-ヴ０-９々〆〤]/gu,
};

export const comDevice = {
  no_com_device: '通信機無し',
  com_equipment_in_prep: '通信機準備中',
  not_obtained: '承諾書未取得',
  consent: '個別承諾or一括承諾or電子承諾',
  consent_excluding_position: '個別承諾or一括承諾or電子承諾(位置除く）',
  rejection: '拒否',
  transmission: '通信機休止中',
  com_equipment_cant_used: '通信機使用不可',
};

export const maxRow = {
  maxRowInquiry: 2,
  rowsMax: 3,
};

export const simStatus = {
  0: 'SIM準備中',
  1: 'SIM作動中',
  2: 'SIM休止',
  3: 'SIM解約',
  4: 'SIM解約確定',
};

export const carStatus = {
  1: 'self_propelled_accident',
  2: 'wrecker',
  3: 'other_manufacturers',
  4: 'tire_drop',
  5: 'fire',
  6: 'personal_injury',
  7: 'specific_failure',
  8: 'serious_case',
};

export const writtenConsent = {
  '00': '取得',
  '10': '承諾',
  '11': '拒否（位置除く）',
  '20': '拒否',
  '21': '完全拒否',
};

export const maxLengthInput = {
  reception_number: 12,
  phone_number: 13,
  kana: 1,
  series: 4,
  inquiry_number: 12,
  bureau: 4,
  category_number: 3,
  katakana: 1,
  designatedNumber: 4,
  person_in_charge_name: 8,
  receiver_name_kana: 26,
  varchar255: 255,
  varchar1000: 1000,
  varchar100: 100,
  varchar80: 80,
  varchar50: 50,
  varchar15: 15,
  maxFileSize: 5000000,
  maxFileSizeString: '5MB',
  cd_company_customer: 9,
  vehicle_number_outbound: 7,
};

export const accountType = {
  external: '（メーカー・販売会社入力欄）',
  internal: '',
};

export const onboardEquipment = {
  '0': '車載器無し',
  '1': '車載器有り',
};

export const buttonServiceDivisionId = {
  A: 1,
  B: 2,
  C: 3,
  J: 4,
  Cancel: 5,
  Other: 6,
};

export const acceptanceDocument = {
  '00': '未取得',
  '10': '承諾',
  '11': '拒否（位置除く）',
  '20': '拒否',
  '21': '完全拒否',
};

export const userInformationStatus = {
  '0': '未発行（ユーザーなし）',
  '1': '発行済（ユーザーあり）',
  '2': '解約済',
  '3': '統合による削除（未連携項目）',
};

export const classCustom = {
  handleMemo: 'handle',
};

export const workReports = {
  maxRecords: 3,
};

export const defaultRowPerPage = 10;

export const defaultPage = 1;

export const maxItem = 10;

export const customerRank = {
  unset: 'N客',
  sRank: 'Ｓ客',
};

export const emailTemplateType = {
  forCallCenterAgent: 'OPエスカレ定型文',
};

export const WreckerClassification = [
  {
    key: 'レッカー搬送',
    text: 'レッカー搬送',
    value: 'レッカー搬送',
  },
  {
    key: '引き出し・引き起こし',
    text: '引き出し・引き起こし',
    value: '引き出し・引き起こし',
  },
  {
    key: 'ロードサービス',
    text: 'ロードサービス',
    value: 'ロードサービス',
  },
  {
    key: 'レッカー+ロードサービス',
    text: 'レッカー+ロードサービス',
    value: 'レッカー+ロードサービス',
  },
];

export const ReasonForCancellation = [
  {
    key: '',
    text: '',
    value: '',
  },
  {
    key: 'トラブル解消',
    text: 'トラブル解消',
    value: 'トラブル解消',
  },
  {
    key: '保留',
    text: '保留',
    value: '保留',
  },
  {
    key: '様子見',
    text: '様子見',
    value: '様子見',
  },
  {
    key: '時間がない',
    text: '時間がない',
    value: '時間がない',
  },
  {
    key: '他を手配',
    text: '他を手配',
    value: '他を手配',
  },
  {
    key: '出動不可',
    text: '出動不可',
    value: '出動不可',
  },
  {
    key: '間違い',
    text: '間違い',
    value: '間違い',
  },
];

export const workingStatus = {
  active: '有',
  inactive: '無',
};

export const jobCancellationStatusA = {
  beforeDispatch: '出動前',
  afterDispatch: '出動後',
};

export const jobCancellationStatusJ = {
  beforeDispatch: '出動前キャンセル',
  beforeDispatchArrival: '出動後現場到着前キャンセル',
  afterDispatchArrival: '出動後現場到着後キャンセル',
};

export const colorButtonAJ = {
  yellow: '#FFD700',
  green: '#228B22',
  brown: '#964B00',
  cyan: '#00FFFF',
  blue: '#4169E1',
};

export const disableColorShow = {
  outerSpace: '#263238',
};

export const colorColumnChart = {
  gray: '#C9CACA',
  brown: '#B96C4D',
  colorStackedColumn1: '#F9D586',
  colorStackedColumn2: '#F5AC58',
  colorStackedColumn3: '#F8CA44',
  colorClusteredColumn1: '#EFC09B',
  colorClusteredColumn2: '#A4BADF',
  colorClusteredColumn3: '#BDD6A4',
};

export const colorOutboundTable = {
  yellow: '#ffe272',
  orange: '#ea9a7f',
  green: '#bddfb5',
  greenBold: '#b5d7ad',
  white: '#f1f1f1',
  black: '#464241',
  softBlack: '#dedee0',
};

export const button = {
  idA: 1,
  idB: 2,
  idC: 3,
  idJ: 4,
  idCancel: 5,
  idOther: 6,
  defaultId: [1, 4],
};

export const maxLengthInputHitoss = {
  shikyoku_nm: 4,
  number_bun: 3,
  number_kana: 1,
  number_ren: 4,
};

export const selfPropelled = {
  0: '否',
  1: '可',
};

export const load = {
  0: '無',
  1: '有',
};

export const MaintenanceTransaction = {
  0: '無',
  1: '有',
};

export const hitossVehicleRegistration = {
  0: '無',
  1: '有',
  2: '一部空欄',
};

export const justDoItListCall = {
  outgoing_call_count: '発信予定',
  not_arranged_count: '未手配',
  main_line_not_arranged_count: '本線未手配',
};

export const vehicleName = {
  1: '日野',
  2: '三菱',
  3: 'いすゞ',
  4: 'ＵＤトラックス',
  5: 'トヨタ',
  6: '日産',
  7: 'マツダ',
  8: 'ダイハツ',
  9: 'プジョー',
  10: 'スカニア',
  11: 'ベンツ',
  12: 'ボルボ',
  13: '輸入車その他',
  14: '国産その他',
};

export const draftValue = {
  true: '1',
  false: '0',
};

export const userStatus = {
  0: '未発行',
  1: '発行済',
  2: '解約済',
  3: 'ID統合による削除',
};

export const secretEncodeText = '~!$^&*';

export const reporter = {
  1: '東北海道日野',
  2: '北海道日野',
  3: '函館日野',
  4: '青森日野',
  5: '岩手日野',
  6: '宮城日野',
  7: '西東北日野',
  8: '福島日野',
  9: '栃木日野',
  10: '群馬日野',
  11: '茨城日野',
  12: '新潟日野',
  13: '千葉日野',
  14: '東京日野',
  15: '横浜日野',
  16: '山梨日野',
  17: '静岡日野',
  18: '愛知日野',
  19: '岐阜日野',
  20: '三重日野',
  21: '富山日野',
  22: '石川日野',
  23: '福井日野',
  24: '長野日野',
  25: '京都日野',
  26: '滋賀日野',
  27: '大阪日野',
  28: '奈良日野',
  29: '和歌山日野',
  30: '神戸日野',
  31: '日ノ丸総本社',
  32: '島根日野',
  33: '岡山日野',
  34: '広島日野',
  35: '山口日野',
  36: '徳島日野',
  37: '香川日野',
  38: '愛媛日野',
  39: '高知日野',
  40: '九州日野',
  41: '熊本日野',
  42: '南九州日野',
  43: '沖縄日野',
};

export const reporterBranch = {
  7: '札幌支店',
  8: '岩見沢支店',
  9: '小樽支店',
  10: '空知支店',
  11: '苫小牧支店',
  12: '室蘭支店',
  13: '千歳支店',
  14: '札幌北支店',
  15: '旭川支店',
};
export const nexcoRequestedConst = {
  true: '1',
};

export const arrangementFlgStatus = {
  0: '有',
  1: '無',
};
export const arrangementFlgStatusHitoss = {
  0: '無',
  1: '有',
};
export const adaptationTimeZone = {
  0: '夜のみ',
  1: '共通',
};

export const limitCompanyNameSearch = 100;

export const listServiceDivision = {
  1: 'A対応',
  2: 'B対応',
  3: 'C対応',
  4: 'J対応',
  5: 'キャンセル',
  6: '問い合わせ/その他',
  '4,1': 'A/J対応',
  '1,4': 'A/J対応',
  '2,1,4': 'A/J/B対応',
  '2,4,1': 'A/J/B対応',
  '1,2,4': 'A/J/B対応',
  '1,4,2': 'A/J/B対応',
  '4,1,2': 'A/J/B対応',
  '4,2,1': 'A/J/B対応',
  '2,4': 'J/B対応',
  '4,2': 'J/B対応',
  '1,2': 'A/B対応',
  '2,1': 'A/B対応',
};

export const listServiceDivisionName = {
  AB: 'A対応/B対応',
  AJ: 'A対応/J対応',
  JB: 'J対応/B対応',
  ABJ: 'A対応/J対応/B対応',
}

export const buttonServiceDivision = {
  A: 'A',
  B: 'B',
  C: 'C'
}

export const hinoSosWorkReportTab = {
  tab1: 1,
  tab2: 2,
  tab3: 3,
};

export const listHitossUrl = ['10.40.1.206', '180.148.31.110'];

export const maxLengthProcessHistoryFields = 75;
export const sizeReturnOutMove = 30;

export const pathCreateHinoSos = '/hino-sos/inquiry-form';

export const pathDownloadFileSOS = 'api/hinoSosInquiries/download';
export const pathDownloadFileConnect = 'api/hinoConnectInquiries/download';
export const pathDownloadFileCallAfter = 'api/outbound/outgoing/afterCalls/download';
export const pathDownloadFileCallBefore = 'api/outbound/outgoing/beforeCalls/download';

export const colorOutBound = {
  yellow: '#edcb91',
  blue: '#a3cae0',
  white: '#fff',
  whiteSmoke: '#f1f1f1',
  craterBrown: '#464241',
  rust: '#a03600',
  green: '#BEE0B6',
};

export const OutboundSymbol = {
  R: 'Ⓡ',
  S: 'Ⓢ',
  M: 'Ⓜ',
};
export const routeJustDoItCallApplication = '/just-do-it/call-application';

export const hitossNoteMiss = {
  nameOfTheMainDistributor: '元販社',
  nameOfTheOfficeInChargeOfTheCompany: '元拠点',
  billingName: '請求先',
  nameOfTheUsers: '使用者',
};

export const fullWidthNumber = {
  1: '１',
  2: '２',
  3: '３',
  4: '４',
  5: '５',
  6: '６',
  7: '７',
  8: '８',
  9: '９',
  10: '１０',
  11: '１１',
  12: '１２',
  13: '１３',
  14: '１４',
  15: '１５',
  16: '１６',
  17: '１７',
  18: '１８',
  19: '１９',
  20: '２０',
  21: '２１',
  22: '２２',
  23: '２３',
  24: '２４',
  25: '２５',
  26: '２６',
  27: '２７',
  28: '２８',
  29: '２９',
  30: '３０',
  31: '３１',
  32: '３２',
  33: '３３',
  34: '３４',
  35: '３５',
  36: '３６',
  37: '３７',
  38: '３８',
  39: '３９',
  40: '４０',
  41: '４１',
  42: '４２',
  43: '４３',
  44: '４４',
  45: '４５',
  46: '４６',
  47: '４７',
  48: '４８',
  49: '４９',
  50: '５０',
  51: '５１',
  52: '５２',
  53: '５３',
  54: '５４',
  55: '５５',
  56: '５６',
  57: '５７',
  58: '５８',
  59: '５９',
  60: '６０',
  61: '６１',
  62: '６２',
  63: '６３',
  64: '６４',
  65: '６５',
  66: '６６',
  67: '６７',
  68: '６８',
  69: '６９',
  70: '７０',
  71: '７１',
  72: '７２',
  73: '７３',
  74: '７４',
  75: '７５',
};

export const statusJustDoIt = {
  3: '未発信',
  0: '作完未報告',
  1: '再発信',
  2: '未入力',
};

export const supportedType = [
  'イザコール',
  'J対応',
  'SOS外注',
  'スカニア外注',
  'その他外注',
];

export const dispatchStaffYes = '有';
export const dispatchStaffNo = '無';
export const beforeDispatchStaff = '出動前';
export const cancellingBeforeDispatch = '出動前キャンセル';

export const izaAccessPermission = 'IZA';
export const izaApprovalPermission = 'Approval IZA';
export const sosAccessPermission = 'SOS';
export const sosApprovalPermission = 'Approval SOS';
export const connectAccessPermission = 'CONNECT';
export const connectApprovalPermission = 'Approval CONNECT';
export const outboundCallInternalAccessPermission = 'OUT';
export const outboundCallApprovalPermission = 'Approval OUT';
export const jusdoitAccessPermission = 'JUSTDOIT';
export const jusdoitApprovalPermission = 'Approval JUSTDOIT';
export const accessCallLogPermission = 'access call logs';
export const accessManagementClientPermission = 'access client management';
export const accessManagementUserPermission = 'access user management';
export const accessBillPagePermission = 'access bill page';
export const outboundCallExternalAccessPermission = 'OUT EXTERNAL';

export const userDivisionOperator = 'JNSオペレーター';
export const idMobilots = 9998;
export const nameMobilots = 'MOBILOTS';

export const dataDesireImproveChartData = 1;

export const resultCallImportWareHouseReportOption = 1;
export const numberAfterCallReportOption = 2;
export const operatorDispatchReportOption = 3;
export const numberReportDealerReportOption = 4;
export const serviceSatisfactionReportOption = 5;

export const dataDesireImproveChartType = 1;
export const callAfterSerViceChartType = 2;
export const serviceSatisfactionChartType = 3;

export const keyCallStatusGuided = '4';

export const callAfterOperator = 'アフターコール';
export const historyOperator = '入庫促進';

export const categoryCodeContractType = 'CT36';
export const contractTypeMasterOptionName = {
  r: 'Rプラン',
  s: 'Sプラン',
};

export const inspectionClassification = {
  3: '3ヶ月点検',
  6: '6ヶ月点検',
  12: '12ヶ月点検',
  vehicleInspection: '車検',
  afterCall: 'アフターコール',
};

export const idOptionCallStatusAfter = {
  noProblem: 134,
  thereIsAProblem: 135,
};

export const checkStatusGuided = {
  all: 'ALL',
  s: 'S',
  r: 'R',
  m: 'M',
  halfS: 'HALF_S',
  halfR: 'HALF_R',
  halfM: 'HALF_M',
};

export const keyLocalStorageInquiryId = 'inquiryIds';

export const statusSendDMSend = '要 DM 発送';

export const operatorTable = "OperatorTable";

export const RPlan = "RPlan";
export const SPlan = "SPlan";

export const OptionIntentImportReport = {
  desire_warehouse: '入庫希望',
  is_considering: '検討中',
  undecided: '未定/PR済み',
  not_warehouse: '入庫しない/他社入庫',
  not_vehicle: '車両無し/売却',
  not_received_call: '電話不通',
  total: '総計',
};

export const OptionCallStatusReport = {
  no_problem: '不具合無し',
  there_is_a_problem: '不具合有り',
  not_received_call: '電話不通',
};

export const outboundListUrl = '/outbound/outbound-list';

export const dmContractInputKeys = ['contract_send_DM_id', 'set_date_send_DM'];
export const checkContractInputKeys = [
  'contract_check_car_id',
  'set_date_check_car',
  'max_call_check_car',
];
export const inspectionContract3MonthInputKeys = [
  'contract_maintenance_3_id',
  'set_date_maintenance_3',
  'max_call_maintenance_3',
];
export const inspectionContract6MonthInputKeys = [
  'contract_maintenance_6_id',
  'set_date_maintenance_6',
  'max_call_maintenance_6',
];
export const inspectionContract12MonthInputKeys = [
  'contract_maintenance_12_id',
  'set_date_maintenance_12',
  'max_call_maintenance_12',
];
export const inspectionSevereConditionInputKeys = [
  'severe_condition_id',
  'set_date_severe_condition',
  'max_call_severe_condition',
];
export const afterContractInputKeys = [
  'contract_call_after_id',
  'set_date_call_after',
  'max_call_call_after',
];

export const dmContractTypeRequired = 'DM 発送の契約を選択してください。';
export const dmContractMaxDayRequired = 'DM 発送の日数設定を入力してください。';
export const checkContractTypeRequired = '車検の契約を選択してください。';
export const checkContractMaxDayRequired = '車検の日数設定を入力してください。';
export const checkContractLimitCallRequired = '車検のコール数上限を入力してください。';
export const inspectionContractTypeRequired = ' ヶ月点検の契約を選択してください。';
export const inspectionContractMaxDayRequired = ' ヶ月点検の日数設定を入力してください。';
export const inspectionContractLimitCallRequired = ' ヶ月点検のコール数上限を入力してください。';
export const inspectionSevereConditionTypeRequired = 'シビアコンディション点検の契約を選択してください。';
export const inspectionSevereConditionMaxDayRequired = 'シビアコンディション点検の日数設定を入力してください。';
export const inspectionSevereConditionLimitCallRequired = 'シビアコンディション点検のコール数上限を入力してください。';
export const afterCallContractTypeRequired = 'アフターコールの契約を選択してください。';
export const afterCallContractMaxCallRequired = 'アフターコールの日数設定を入力してください。';
export const afterCallContractLimitCallRequired = 'アフターコールのコール数上限を入力してください。';

export const consentFormVisualizationTool = '承諾書見える化ツール';

export const outgoingStatusAfterCall = {
  there_is_problem: '不具合あり',
  currently_not_use: '現在使われておりません',
};

export const colorOutboundExternalTable = {
  green: '#006400',
  red: '#f71700',
  black: '#464241',
  softBlack: '#dedee0',
};

export const placeholderSearch = {
  vehicle_registration_number_bureau: '八王子',
  vehicle_registration_number_bureau_SMPlan: '和歌山',
  vehicle_registration_number_category: '100',
  vehicle_registration_number_katakana: 'あ',
  vehicle_registration_number_designated_number: '1111',
  vehicle_type: '車型',
  vehicle_number: '車番',
  business_charge_person: '紀伊 太郎',
  business_charge_person_SMPlan: '八王子　太郎',
  sale_representative: '八王子　太郎',
  sale_representative_SRPlan: '紀伊 太郎',
  vehicle_registration_number_bureau_list_SMPlan: '八王子'
};

export const externalPromoteWarehousingCallUrl = '/outbound/promote-warehouse-call-list';
export const externalAfterCallUrl = '/outbound/after-call-list';

export const outgoingContract = ['車検', '点検'];
export const afterOutgoingContract = ['アフターコール'];

export const memoDefault = {
  width: 230,
  height: 199,
};

export const firstTimeInspection = '初回車検';
export const newInspection = '新規車検';
export const reInspection = 'リピート車検';

export const unconfirmedStatus = '未確認';
export const importReportStatus = '要入庫報告';
export const workReportStatus = '要作業報告';